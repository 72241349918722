import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiUrls } from '../api-urls.enum';
import { DataService } from '../core/communication/rest-service-invoker.service';
import { CredentialsService } from '../core/credentials.service';
import { IMenuItem } from './layout.model';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private menu: IMenuItem[];
  private menuSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private dataService: DataService,
    private creds: CredentialsService
  ) {
    //this.menu = data.menu;
  }

  // getDummyMenu(): Observable<IMenu[]> {
  //   return of(this.menu.MN_Menuler.sort((a: any, b: any) => a.categoryId - b.categoryId));
  // }

  userMenus(): Observable<IMenuItem[]> {
    return this.getMenuler();
  }

  getMenu$() {
    return this.menuSubject.asObservable();
  }

  nextMenu(menu: IMenuItem[]) {
    this.menuSubject.next(menu);
  }

  getMenuler() {
    const userData = this.creds.getAllCredential();
    return this.dataService.get<IMenuItem[]>(ApiUrls.GetMenuler, userData);
  }
}
