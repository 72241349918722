import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import { BrandingService } from 'src/app/core/services/branding.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login.service';

interface Image {
  src: string;
  title?: string;
  alt?: string;
}

@Component({
  selector: 'app-password-refresh',
  templateUrl: './password-refresh.component.html',
  styleUrls: ['./password-refresh.component.scss'],
})
export class PasswordRefreshComponent implements OnInit, AfterViewInit {
  passwordForm: FormGroup;
  isLoading = false;
  error: string | undefined;
  private keyStr: string;
  guid: string;
  @ViewChild('cd') private countdown: CountdownComponent;
  counterConfig: CountdownConfig;

  hideNewPass = true;
  hideNewConfirm = true;
  public brandLogo = '';

  imagesData: Image[] = [];
  loadSlider = false;
  customOptions: any = {
    // autoWidth: true,
    loop: true,
    autoHeight: true,
    // lazyLoad: true,
    // lazyLoadEager: 1,
    // animateOut: 'slideOutDown',
    // animateIn: 'flipInX',
    // items: '10',
    // margin: 10,
    // slideBy: 'page',
    // merge: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    // autoplaySpeed: 4000,
    // dotsSpeed: 500,
    dots: false,
    // dotsData: true,
    // mouseDrag: false,
    // touchDrag: false,
    // pullDrag: false,
    // smartSpeed: 400,
    // fluidSpeed: 499,
    // dragEndSpeed: 350,
    // dotsEach: 4,
    center: true,
    URLhashListener: true,
    startPosition: 'URLHash',
    // rewind: true,
    // rtl: true,
    // startPosition: 1,
    // navText: [ '<i class=fa-chevron-left>left</i>', '<i class=fa-chevron-right>right</i>' ],
    responsive: {
      0: {
        items: 1,
      },
    },
    // stagePadding: 40,
    nav: false,
  };
  public APP_LOGO = '';
  public APP_NAME = '';
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private snackBar: SnackBarService,
    private actr: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private brandingService: BrandingService
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      const detay = this.router.getCurrentNavigation().extras.state;
      this.guid = detay.guid;
    } else {
      this.router.navigate(['/password']);
    }
    this.counterConfig = { leftTime: 180, format: 'm:ss', demand: true };
    this.brandingService.init().subscribe(x => {
      this.imagesData = environment.sliderImageData;
      this.APP_LOGO = environment.brandLogo;
      this.APP_NAME = environment.appName;
      this.loadSlider = true;
    });
  }
  ngAfterViewInit(): void {
    this.countdown.restart();
    this.countdown.begin();
  }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required])],
      passwordAgain: ['', Validators.compose([Validators.required])],
      code: ['', Validators.compose([Validators.required])],
    });
  }

  sifremiYenile() {
    this.isLoading = true;
    if (this.passwordForm.valid) {
      if (
        this.passwordForm.controls.password.value !==
        this.passwordForm.controls.passwordAgain.value
      ) {
        this.snackBar.open(
          'Login.PasswordSifreUyusmazlik'
        );
        this.isLoading = false;
        return;
      } else {
        const param = {
          guid: this.guid,
          code: this.passwordForm.controls.code.value,
          sifre: this.passwordForm.controls.password.value,
        };
        this.loginService.sifreYenile(param).subscribe(
          (x: any) => {
            if (x.state == true) {
              this.snackBar
                .open(
                  'Login.PasswordSifreDegistirildi'.getResultMessage(x.message)
                )
                .then(() => {
                  this.isLoading = false;
                  setTimeout(() => {
                    this.router.navigate(['login']);
                  }, 3000);
                });
            } else {
              if (x.message == 'sureasimi') {
                this.snackBar
                  .open('Login.PasswordSureAsimi'.getResultMessage(x.message))
                  .then(() => {
                    this.isLoading = false;
                    setTimeout(() => {
                      this.router.navigate(['/password']);
                    }, 3000);
                  });
              } else if (x.message == 'kodyok') {
                this.snackBar
                  .open('Login.PasswordKodHatali'.getResultMessage(x.message))
                  .then(() => {
                    this.isLoading = false;
                  });
              } else {
                this.snackBar
                  .open(
                    'Global.Error'
                  )
                  .then(() => {
                    this.isLoading = false;
                  });
              }
            }
          },
          (error) => {
            this.snackBar.open(
              'Global.Error'
            );
            this.isLoading = false;
            this.error = error;
          }
        );
      }
    }
  }

  handleCounterEvent(e: any) {
    if (e.action === 'done') {
      this.snackBar.open('Login.PasswordSureAsimi').then(() => {
        this.isLoading = false;
        setTimeout(() => {
          this.router.navigate(['/password']);
        }, 3000);
      });
    }
  }
}
