import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DataService } from '../communication/rest-service-invoker.service';
import { CredentialsService } from '../credentials.service';
import { I18nService } from '../i18n';
import { Credentials, CredentialsDTO } from '../models';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private credentialsService: CredentialsService,
    private dataService: DataService,
    private http: HttpClient,
    private i18nService: I18nService
  ) { }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<Credentials> {
    // Replace by proper authentication call

    return this.dataService
      .post<CredentialsDTO>('authenticate/login-signed', context)
      .pipe(
        map<CredentialsDTO, Credentials>((dto) => {
          const digits = this.i18nService.language.substring(0, 2);
          switch (digits) {
            case 'tr': dto.dilID = 1; dto.dilKodu = 'tr'; break;
            case 'en': dto.dilID = 2; dto.dilKodu = 'en'; break;
            case 'ru': dto.dilID = 3; dto.dilKodu = 'ru'; break;
            default: dto.dilID = 1; dto.dilKodu = 'tr';
          }

          if (dto.isAuth) {
            const cred: Credentials = {
              adiSoyadi: dto.adiSoyadi,
              cryptoTenant: dto.cryptoTenant,
              dilID: dto.dilID,
              dilKodu: dto.dilKodu,
              email: dto.email,
              isAuth: dto.isAuth,
              kullaniciTesisleri: dto.kullaniciTesisleri,
              kullaniciBolumleri: dto.kullaniciBolumleri,
              kullaniciID: dto.kullaniciID,
              kullaniciAdi: dto.kullaniciAdi,
              kullaniciTurID: dto.kullaniciTurID,
              tesisSayisi: dto.tesisSayisi,
              signedKey: dto.signedKey,
              tesisID: dto.tesisID,
              errorMessage: dto.errorMessage,
              varsayilanTesisAdi: dto.varsayilanTesisAdi,
              varsayilanTesisID: dto.varsayilanTesisID,
              firmaID: dto.musteriLoginBilgileri?.firmaID ?? 0,
              firmaKisiID: dto.musteriLoginBilgileri?.firmaKisiID ?? 0,
              mulkiyetDurumID: dto.musteriLoginBilgileri?.mulkiyetDurumID ?? 0,
              bolumID: dto.musteriLoginBilgileri?.bolumID ?? 0,
              doviz: dto.musteriLoginBilgileri?.doviz ?? '',
              cariHesapID: dto.musteriLoginBilgileri?.cariHesapID ?? 0,
              selectedCariHesapID: dto.musteriLoginBilgileri?.cariHesapID ?? 0,
              blokID: dto.musteriLoginBilgileri?.blokID ?? 0,
              tesisGSM: dto.musteriLoginBilgileri?.tesisGSM ?? '',
              logo: dto.musteriLoginBilgileri?.logo ?? '',
              logoDosyaTuru: dto.musteriLoginBilgileri?.logoDosyaTuru ?? '',
              kvkkOnay: dto.musteriLoginBilgileri?.kvkkOnay ?? true,
              resim: dto.musteriLoginBilgileri?.resim ?? '',
              teknikUser: dto.teknikUser,
              appID: dto.appID,
              manuel: false,
              blokKatID: dto.musteriLoginBilgileri.blokKatID,
              bolumAdi: dto.musteriLoginBilgileri.bolumAdi,
              bolumNo: dto.musteriLoginBilgileri.bolumNo,
              blokAdi: dto.musteriLoginBilgileri.blokAdi,
              blokNo: dto.musteriLoginBilgileri.blokNo
            };
            return cred;
          } else {
            throw new Error(dto.errorMessage);
          }
        }),
        tap((data) => {
          this.credentialsService.setCredentials(data);
        })
      );
  }
  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    return of(true);
  }
}
