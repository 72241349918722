<div class="d-flex flex-column flex-lg-row flex-column-fluid">
  <div
    class="d-flex flex-column flex-lg-row-auto w-lg-400px w-xl-600px positon-xl-relative bg-primary"
  >
    <div
      class="d-flex flex-column position-xl-fixed top-0 bottom-0 w-lg-400px w-xl-600px"
    >
      <div class="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
        <a href="#" class="py-9 mb-5">
          <img alt="Logo" [src]="tesisLogo" class="h-60px" />
        </a>
        <h1 class="text-primary-inverse fw-bolder fs-2qx pb-5 pb-md-10">
          {{ tesisBilgileri.tesisAdi }}
        </h1>
      </div>
      <div class="d-flex flex-row-auto min-h-100px owl-wrapper">
        <owl-carousel-o [options]="customOptions" #owlCat *ngIf="loadSlider">
          <ng-container *ngFor="let image of imagesData">
            <ng-template carouselSlide>
              <div class="d-flex flex-row-fluid flex-column text-center">
                <p class="text-primary-inverse fw-bold fs-2 p-10 pt-lg-20">
                  {{ image.title | translate }}
                </p>
                <img
                  class="d-none d-lg-block"
                  [src]="image.src"
                  [alt]="image.alt"
                  title="{{ image.title | translate }}"
                />
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column flex-lg-row-fluid py-10">
    <div class="d-flex flex-center flex-column flex-column-fluid">
      <div class="w-lg-700px p-10 p-lg-15 mx-auto">
        <form (ngSubmit)="register()" [formGroup]="registerForm" novalidate>
          <div class="text-start mb-10">
            <h1 class="text-dark fw-bolder mb-3">
              {{ "Register.Welcome" | translate }}
            </h1>
            <div class="text-gray-500 fw-semibold fs-6">
              {{ "Register.Description" | translate }}
            </div>
          </div>

          <div
            style="
              box-sizing: border-box;
              display: grid;
              grid-template-columns: repeat(2, 288px);
              grid-column-gap: 25px;
            "
          >
            <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
              <input
                type="text"
                matInput
                class="form-control form-control-solid"
                formControlName="adi"
                autocomplete="adi"
                [placeholder]="'Register.Name' | translate"
                required
              />
              <mat-error
                *ngIf="
                  registerForm.controls.adi.invalid &&
                  registerForm.controls.adi.touched
                "
              >
                <span> {{ "Register.RequiredField" | translate }} </span>
              </mat-error>
            </mat-form-field>

            <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
              <input
                type="text"
                matInput
                class="form-control form-control-solid"
                formControlName="soyadi"
                autocomplete="soyadi"
                [placeholder]="'Register.Surname' | translate"
                required
              />
              <mat-error
                *ngIf="
                  registerForm.controls.soyadi.invalid &&
                  registerForm.controls.soyadi.touched
                "
              >
                <span> {{ "Register.RequiredField" | translate }} </span>
              </mat-error>
            </mat-form-field>

            <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
              <input
                type="text"
                matInput
                class="form-control form-control-solid"
                formControlName="tcKimlikNo"
                autocomplete="tcKimlikNo"
                [placeholder]="'Register.TcIdentificationNumber' | translate"
                pattern="\d*"
                maxlength="11"
                required
              />
              <mat-error
                *ngIf="
                  registerForm.controls.tcKimlikNo.invalid &&
                  registerForm.controls.tcKimlikNo.touched
                "
              >
                <span> {{ "Register.RequiredField" | translate }} </span>
              </mat-error>
            </mat-form-field>

            <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
              <input
                type="text"
                matInput
                class="form-control form-control-solid"
                formControlName="eposta"
                autocomplete="eposta"
                [placeholder]="'Register.Email' | translate"
                required
              />
              <mat-error
                *ngIf="
                  registerForm.controls.eposta.invalid &&
                  registerForm.controls.eposta.touched
                "
              >
                <span> {{ "Register.RequiredField" | translate }} </span>
              </mat-error>
            </mat-form-field>

            <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
              <input
                type="text"
                matInput
                [angularFormsMask]="['(DDD) DDD DD DD']"
                class="form-control form-control-solid"
                [validateMaskInput]="true"
                formControlName="cepTelNo"
                autocomplete="cepTelNo"
                [placeholder]="'Register.CellPhoneNumber' | translate"
                required
              />
              <mat-error
                *ngIf="
                  registerForm.controls.cepTelNo.invalid &&
                  registerForm.controls.cepTelNo.touched
                "
              >
                <span> {{ "Register.RequiredField" | translate }} </span>
              </mat-error>
            </mat-form-field>

            <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
              <input
                type="text"
                matInput
                [angularFormsMask]="['(DDD) DDD DD DD']"
                [validateMaskInput]="true"
                class="form-control form-control-solid"
                formControlName="telNo"
                autocomplete="telNo"
                [placeholder]="'Register.BusinessPhoneNumber' | translate"
                required
              />
              <mat-error
                *ngIf="
                  registerForm.controls.telNo.invalid &&
                  registerForm.controls.telNo.touched
                "
              >
                <span> {{ "Register.RequiredField" | translate }} </span>
              </mat-error>
            </mat-form-field>

            <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
              <mat-label>{{ "Register.Blocks" | translate }}</mat-label>
              <mat-select
                class="form-select form-select-solid"
                formControlName="blokID"
                required
                (selectionChange)="blokChange($event)"
              >
                <mat-option *ngFor="let blok of bloklar" [value]="blok.blokID">
                  {{ blok.blokNo }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  registerForm.controls.blokID.invalid &&
                  registerForm.controls.blokID.touched
                "
              >
                <span> {{ "Register.RequiredField" | translate }} </span>
              </mat-error>
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{ "Register.Sections" | translate }}</mat-label>
              <mat-select
                class="form-select form-select-solid"
                formControlName="bolumID"
                required
              >
                <mat-option
                  *ngFor="let bolum of bolumler"
                  [value]="bolum.bolumID"
                >
                  {{ bolum.bolumAdi }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  registerForm.controls.bolumID.invalid &&
                  registerForm.controls.bolumID.touched
                "
              >
                <span> {{ "Register.RequiredField" | translate }} </span>
              </mat-error>
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{ "Register.PropertyStatus" | translate }}</mat-label>
              <mat-select
                class="form-select form-select-solid"
                formControlName="mulkiyetDurumID"
                required
              >
                <mat-option
                  *ngFor="let mulkiyetDurumu of mulkiyetDurumlari"
                  [value]="mulkiyetDurumu.mulkiyetDurumID"
                >
                  {{ mulkiyetDurumu.mulkiyetDurumu }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  registerForm.controls.mulkiyetDurumID.invalid &&
                  registerForm.controls.mulkiyetDurumID.touched
                "
              >
                <span> {{ "Register.RequiredField" | translate }} </span>
              </mat-error>
            </mat-form-field>

            <mat-form-field
              floatLabel="always"
              [hideRequiredMarker]="true"
              class="mb-4"
            >
              <input
                type="password"
                matInput
                class="form-control form-control-solid"
                formControlName="sifre"
                [placeholder]="'Register.Password' | translate"
                required
              />
              <mat-error
                *ngIf="
                  registerForm.controls.sifre.invalid &&
                  registerForm.controls.sifre.touched
                "
              >
                <span> {{ "Register.RequiredField" | translate }} </span>
              </mat-error>
            </mat-form-field>

            <mat-form-field
              floatLabel="always"
              [hideRequiredMarker]="true"
              class="mb-4"
            >
              <input
                type="password"
                matInput
                class="form-control form-control-solid"
                formControlName="sifreTekrar"
                [placeholder]="'Register.PasswordRepeat' | translate"
                required
              />
              <mat-error
                *ngIf="
                  registerForm.controls.sifreTekrar.invalid &&
                  registerForm.controls.sifreTekrar.touched
                "
              >
                <span> {{ "Register.RequiredField" | translate }} </span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="d-flex flex-column align-items-center">
            <button
              class="btn btn-lg btn-primary w-50"
              type="submit"
              [disabled]="(registerForm.invalid && submit) || isLoading"
              [attr.data-indicator]="isLoading ? 'on' : null"
            >
              <span class="indicator-label">
                {{ "Register.Send" | translate }}
              </span>
              <span class="indicator-progress"
                >{{ "Global.LutfenBekleyin" | translate }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>

            <div class="text-center text-muted fw-bolder my-10">
              {{ "Login.Yada" | translate }}
            </div>

            <div class="text-center mb-10">
              <div class="text-gray-400 fw-bold fs-4">
                <a
                  class="link-primary cursor-pointer fw-bolder"
                  (click)="goBack()"
                  >{{ "Global.Back" | translate }}</a
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="d-flex flex-center flex-wrap px-5">
      <div class="d-flex fw-semibold text-primary fs-base">
        <app-language-selector></app-language-selector>
      </div>
    </div>
  </div>
</div>

<!-- <mat-sidenav-container>
  <mat-sidenav-content>
    <mat-toolbar fxLayout="column" fxLayoutAlign="start center" class="h-auto">
      <section fxLayoutAlign="space-between center" class="w-100 py-3">
        <div fxLayoutAlign="start center">
          <button
            (click)="goBack()"
            class="mr-3"
            mat-icon-button
            matTooltip="{{ 'Global.Geri' | translate }}"
          >
            <mat-icon>arrow_back</mat-icon>
          </button>
          <span class="mr-4">{{ "Register.Title" | translate }}</span>
          <img class="width-100 d-none d-sm-block" [src]="tesisLogo" />
        </div>
        <span class="fs-30px d-none d-sm-block">{{
          tesisBilgileri.tesisAdi
        }}</span>
        <app-language-selector></app-language-selector>
      </section>
      <div class="separator d-sm-none"></div>
      <section
        class="w-100 py-3 d-sm-none"
        fxLayoutAlign="space-between center"
      >
        <span class="w-75 text-truncate mx-3">{{
          tesisBilgileri.tesisAdi
        }}</span>
        <img class="width-100" [src]="tesisLogo" />
      </section>
    </mat-toolbar>
    <section class="page-content">
      <perfect-scrollbar>
        <form
          (ngSubmit)="register()"
          [formGroup]="registerForm"
          novalidate
          class="pt-4"
        >
          <div class="row m-0">
            <div class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
              <mat-form-field
                appearance="outline"
                class="w-100"
                [hideRequiredMarker]="true"
              >
                <mat-label>{{ "Register.Name" | translate }}</mat-label>
                <input type="text" matInput formControlName="adi" required />
                <mat-error
                  *ngIf="
                    registerForm.controls.adi.invalid &&
                    registerForm.controls.adi.touched
                  "
                >
                  <span> {{ "Register.RequiredField" | translate }} </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
              <mat-form-field
                class="w-100"
                appearance="outline"
                [hideRequiredMarker]="true"
              >
                <mat-label>{{ "Register.Surname" | translate }}</mat-label>
                <input type="text" matInput formControlName="soyadi" required />
                <mat-error
                  *ngIf="
                    registerForm.controls.soyadi.invalid &&
                    registerForm.controls.soyadi.touched
                  "
                >
                  <span> {{ "Register.RequiredField" | translate }} </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
              <mat-form-field
                class="w-100"
                appearance="outline"
                [hideRequiredMarker]="true"
              >
                <mat-label>{{
                  "Register.TcIdentificationNumber" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="tcKimlikNo"
                  type="text"
                  pattern="\d*"
                  maxlength="11"
                  required
                />
                <mat-error
                  *ngIf="
                    registerForm.controls.tcKimlikNo.invalid &&
                    registerForm.controls.tcKimlikNo.touched
                  "
                >
                  <span> {{ "Register.RequiredField" | translate }} </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
              <mat-form-field
                class="w-100"
                appearance="outline"
                [hideRequiredMarker]="true"
              >
                <mat-label>{{ "Register.Email" | translate }}</mat-label>
                <input type="text" matInput formControlName="eposta" required />
                <mat-error
                  *ngIf="
                    registerForm.controls.eposta.invalid &&
                    registerForm.controls.eposta.touched
                  "
                >
                  <span> {{ "Register.RequiredField" | translate }} </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
              <mat-form-field
                class="w-100"
                appearance="outline"
                [hideRequiredMarker]="true"
              >
                <mat-label>{{
                  "Register.CellPhoneNumber" | translate
                }}</mat-label>
                <input
                  [angularFormsMask]="['(DDD) DDD DD DD']"
                  [validateMaskInput]="true"
                  matInput
                  formControlName="cepTelNo"
                  required
                />
                <mat-error
                  *ngIf="
                    registerForm.controls.cepTelNo.invalid &&
                    registerForm.controls.cepTelNo.touched
                  "
                >
                  <span> {{ "Register.RequiredField" | translate }} </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
              <mat-form-field
                class="w-100"
                appearance="outline"
                [hideRequiredMarker]="true"
              >
                <mat-label>{{
                  "Register.BusinessPhoneNumber" | translate
                }}</mat-label>
                <input
                  [angularFormsMask]="['(DDD) DDD DD DD']"
                  [validateMaskInput]="true"
                  matInput
                  formControlName="telNo"
                  required
                />
                <mat-error
                  *ngIf="
                    registerForm.controls.telNo.invalid &&
                    registerForm.controls.telNo.touched
                  "
                >
                  <span> {{ "Register.RequiredField" | translate }} </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
              <mat-form-field
                class="w-100"
                appearance="outline"
                [hideRequiredMarker]="true"
              >
                <mat-label>{{ "Register.Blocks" | translate }}</mat-label>
                <mat-select
                  formControlName="blokID"
                  required
                  (selectionChange)="blokChange($event)"
                >
                  <mat-option
                    *ngFor="let blok of bloklar"
                    [value]="blok.blokID"
                  >
                    {{ blok.blokNo }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    registerForm.controls.blokID.invalid &&
                    registerForm.controls.blokID.touched
                  "
                >
                  <span> {{ "Register.RequiredField" | translate }} </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
              <mat-form-field
                class="w-100"
                appearance="outline"
                [hideRequiredMarker]="true"
              >
                <mat-label>{{ "Register.Sections" | translate }}</mat-label>
                <mat-select formControlName="bolumID" required>
                  <mat-option
                    *ngFor="let bolum of bolumler"
                    [value]="bolum.bolumID"
                  >
                    {{ bolum.bolumAdi }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    registerForm.controls.bolumID.invalid &&
                    registerForm.controls.bolumID.touched
                  "
                >
                  <span> {{ "Register.RequiredField" | translate }} </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
              <mat-form-field
                class="w-100"
                appearance="outline"
                [hideRequiredMarker]="true"
              >
                <mat-label>{{
                  "Register.PropertyStatus" | translate
                }}</mat-label>
                <mat-select formControlName="mulkiyetDurumID" required>
                  <mat-option
                    *ngFor="let mulkiyetDurumu of mulkiyetDurumlari"
                    [value]="mulkiyetDurumu.mulkiyetDurumID"
                  >
                    {{ mulkiyetDurumu.mulkiyetDurumu }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    registerForm.controls.mulkiyetDurumID.invalid &&
                    registerForm.controls.mulkiyetDurumID.touched
                  "
                >
                  <span> {{ "Register.RequiredField" | translate }} </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
              <mat-form-field
                class="w-100"
                appearance="outline"
                [hideRequiredMarker]="true"
              >
                <mat-label>{{ "Register.Password" | translate }}</mat-label>
                <input
                  type="password"
                  matInput
                  formControlName="sifre"
                  required
                />
                <mat-error
                  *ngIf="
                    registerForm.controls.sifre.invalid &&
                    registerForm.controls.sifre.touched
                  "
                >
                  <span> {{ "Register.RequiredField" | translate }} </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
              <mat-form-field
                class="w-100"
                appearance="outline"
                [hideRequiredMarker]="true"
              >
                <mat-label>{{
                  "Register.PasswordRepeat" | translate
                }}</mat-label>
                <input
                  type="password"
                  matInput
                  formControlName="sifreTekrar"
                  required
                />
                <mat-error
                  *ngIf="
                    registerForm.controls.sifreTekrar.invalid &&
                    registerForm.controls.sifreTekrar.touched
                  "
                >
                  <span> {{ "Register.RequiredField" | translate }} </span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
        <mat-toolbar class="action-buttons border-top">
          <button
            mat-raised-button
            class="px-5 py-2"
            [ngClass.lt-sm]="'w-100'"
            color="primary"
            type="button"
            (click)="register()"
            [disabled]="registerForm.invalid || submit"
          >
            <span class="font-size-16">
              {{ "Register.Send" | translate }}
            </span>
          </button>
        </mat-toolbar>
      </perfect-scrollbar>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container> -->
