import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SanalPosBankalarEnum } from 'src/app/shared/enums/enums';

@Component({
  selector: 'app-banka-submit-form',
  templateUrl: './banka-submit-form.component.html',
  styleUrls: ['./banka-submit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankaSubmitFormComponent implements OnInit {
  // @Output() onSubmit = new EventEmitter();
  @ViewChild('bankaSubmitForm') bankaSubmitForm: ElementRef<HTMLFormElement>;
  public iyzicoHtmlContent: any;
  public isRendered: boolean;
  public iyzico: boolean = false;
  private _bankaSubmitData: any;
  form = new FormGroup({});
  fields: any[] = [];
  dd: ElementRef<any>;
  constructor(
    private elRef: ElementRef,
    protected sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    this.isRendered = false;
  }

  public get bankaSubmitData() {
    return this._bankaSubmitData;
  }
  @Input() public set bankaSubmitData(val) {
    this._bankaSubmitData = val;

    this.fields = this.bankaSubmitData.bankaPosParametreleri
    for (let f of this.fields) {
      this.form.addControl(
        f.key,
        new FormControl(f.value, Validators.required)
      );
    }

    if (
      this.bankaSubmitData.bankaBilgileri.bankaTurID ===
      SanalPosBankalarEnum.Iyzico
    ) {
      this.iyzicoHtmlContent = this.sanitizer.bypassSecurityTrustHtml(
        this.fields.find((x) => x.key === 'HtmlContent')?.value
      );
      this.iyzico = true;
    }
    this.isRendered = true;
    this.cdr.detectChanges();
    //debugger;
    this.odemeFormSubmit();
  }
  ngOnInit(): void { }

  odemeFormSubmit(): void {
    setTimeout(() => {
      if (this.bankaSubmitData.bankaBilgileri.formOlmadanYonlendir === true) {
        window.location.href = this.bankaSubmitData.bankaBilgileri.postURL;
      } else if (
        this.bankaSubmitData.bankaBilgileri.bankaTurID ===
        SanalPosBankalarEnum.Iyzico
      ) {
        // const form = this.elRef.nativeElement.querySelector('#step1Form');
        const form = this.elRef.nativeElement.querySelector('form');
        form.submit();
      } else {
        //debugger;
        this.bankaSubmitForm.nativeElement.submit();
      }
    }, 1000);
  }
}
