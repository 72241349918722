import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IStatusModel } from '../../../shared/models/IStatusModel';
import { ExternalPaymentService } from '../external-payment/external-payment.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalHtmlReportsResolver {
  constructor(private externalPaymentService: ExternalPaymentService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<IStatusModel> {
    const guid = route.paramMap.get('guidLink') ?? '';
    return this.externalPaymentService.getExernalHtmlReport(guid);
  }
}
