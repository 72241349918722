export enum ApiUrls {
  /*V3-Begin*/
  GetAnketler = 'endUserWeb/Anketler/GetAnketlerV3',
  GetAnketSorulari = 'endUserWeb/Anketler/GetAnketSorulariV3',
  SaveAnket = 'endUserWeb/Anketler/SaveAnketV3',
  BankaBilgileri = 'endUserWeb/BankaBilgileri/GetBankaBilgileriV3',
  GetKisiselBildirimler = 'endUserWeb/bildirimler/GetKisiselBildirimlerV3',
  GetKisiselBildirimDetay = 'endUserWeb/bildirimler/GetKisiselBildirimDetayV3',
  GetBilgilerim = 'endUserWeb/bilgilerim/GetBilgilerimV3',
  GetDosyalar = 'endUserWeb/common/GetKEDosyalarV3',
  GetDosyaDetay = 'endUserWeb/common/GetKEDosyaDetayV3',
  GetMenuler = 'endUserWeb/common/GetMenulerV3',
  GetDashBoardData = 'endUserWeb/userWeb/GetDashboardDataV3',
  GetKullaniciBolumBilgisi = 'endUserWeb/userWeb/GetKullaniciBolumBilgisiV3',
  GetDokumanlarAllData = 'endUserWeb/dokumanlar/GetDokumanlarAllDataV3',
  GetKlasorler = 'endUserWeb/dokumanlar/GetKlasorlerV3',
  GetDokumanlar = 'endUserWeb/dokumanlar/GetDokumanlarV3',
  GetDokuman = 'endUserWeb/dokumanlar/GetDokumanV3',
  GetDuyurular = 'endUserWeb/duyurular/GetDuyurularV3',
  GetDuyuruDetay = 'endUserWeb/duyurular/GetDetayV3',
  GetEtkinlikler = 'endUserWeb/etkinlikler/GetEtkinliklerV3',
  GetEtkinlikDetay = 'endUserWeb/etkinlikler/GetEtkinlikV3',
  GetGelirGiderHareketleri = 'endUserWeb/gelirGider/GetGelirGiderHareketleriV3',
  GetGelirGiderHareketDetay = 'endUserWeb/gelirGider/GetGelirGiderHareketDetayV3',
  GetGizlilikPolitikasi = 'endUserWeb/gizlilikPolitikasi/GetGizlilikPolitikasiV3',
  GetSanalPosSozlesmesi = 'endUserWeb/gizlilikPolitikasi/GetKullaniciSozlesmesi',
  GetHesapHareketleri = 'endUserWeb/hesapHareketleri/GetHesapHareketleriV3',
  GetBolumHesaplari = 'endUserWeb/hesapHareketleri/GetBolumHesaplariV3',
  GetTahsilatMakbuzu = 'endUserWeb/hesapHareketleri/GetTahsilatMakbuzuV3',
  GetDonemselButceGerceklesenRaporu = 'endUserWeb/hesapHareketleri/GetDonemselButceGerceklesenRaporuV3',
  GetDonemselButceGerceklesenRaporuDetay = 'endUserWeb/hesapHareketleri/GetDonemselButceGerceklesenRaporuDetayV3',
  GetIletisimBilgileri = 'endUserWeb/siteBilgileri/GetIletisimBilgileriV3',
  GetSiteOzetBilgileri = 'endUserWeb/siteBilgileri/GetSiteOzetBilgileriV3',
  GetKararlar = 'endUserWeb/kararlar/GetKararlarV3',
  GetKarar = 'endUserWeb/kararlar/GetKararV3',
  GetMekanRezervasyonlari = 'endUserWeb/mekanRezervasyonu/GetMekanRezervasyonlariV3',
  GetMekanlar = 'endUserWeb/mekanRezervasyonu/GetMekanlarV3',
  CreateMekanRezervasyon = 'endUserWeb/mekanRezervasyonu/CreateMekanRezervasyonV3',
  CancelMekanezervasyon = 'endUserWeb/mekanRezervasyonu/CancelMekanezervasyonV3',
  GetMekanRezervasyonAlinanSeanslar = 'endUserWeb/mekanRezervasyonu/GetMekanRezervasyonAlinanSeanslarV3',
  GetMekanRezervasyonSeanslar = 'endUserWeb/mekanRezervasyonu/GetMekanRezervasyonSeanslarV3',
  CreateMekanRezervasyonSeans = 'endUserWeb/mekanRezervasyonu/CreateMekanRezervasyonSeansV3',
  GetMekanRezervasyonSeansKontrol = 'endUserWeb/mekanRezervasyonu/GetMekanRezervasyonSeansKonrolV3',
  CancelMekanRezervasyonSeans = 'endUserWeb/mekanRezervasyonu/CancelMekanRezervasyonSeansV3',
  GetNakitAkis = 'endUserWeb/nakitAkis/GetNakitAkisV3',

  GetTalepler = 'endUserWeb/talepler/GetTaleplerV3',
  GetTalepTurleri = 'endUserWeb/talepler/GetTalepTurleriV3',
  GetTalep = 'endUserWeb/talepler/DetayV3',
  GetTalepKategorileri = 'endUserWeb/talepler/TalepKategorileriV3',
  GetTalepAltKategorileri = 'endUserWeb/talepler/GetTalepAltKategorileriV3',
  SaveTalepler = 'endUserWeb/talepler/SaveTaleplerV3',
  UpdateTalepFiles = 'endUserWeb/talepler/UpdateTalepFiles',
  TalepIptal = 'endUserWeb/talepler/TalepIptalV3',
  GetTelefonRehberi = 'endUserWeb/telefonRehberi/GetTelefonRehberiV3',
  GetZiyaretciler = 'endUserWeb/ziyaretciler/GetZiyaretcilerV3',
  SaveZiyaretci = 'endUserWeb/ziyaretciler/SaveZiyaretciV3',
  DeleteZiyaretci = 'endUserWeb/ziyaretciler/DeleteZiyaretciV3',
  GetZiyaretciGirisQrCode = 'endUserWeb/ZiyaretciQR/ZiyaretciGirisQrCode',

  GetBlokRandevulari = 'endUserWeb/BlokRandevulari/GetBlokRandevulari',
  GetBlokRandevuOlusturData = 'endUserWeb/BlokRandevulari/GetBlokRandevuOlusturData',
  SaveBlokRandevu = 'endUserWeb/BlokRandevulari/SaveBlokRandevu',
  DeleteBlokRandevu = 'endUserWeb/BlokRandevulari/DeleteBlokRandevu',
  GetMenuIsActive = 'endUserWeb/Common/GetMenuIsActive',
  /*V3-End*/
  Login = 'authenticate/login-signed',
  SifremiUnuttum = 'authenticate/NSifremiUnuttum',
  SifreYenile = 'authenticate/NSifreYenile',
  SmsSignUp = 'authenticate/SmsSignUp',
  SmsSignIn = 'authenticate/SmsSignIn',
  ChangePassword = 'authenticate/changePassword-signedv3',
  GetTesisParametre = 'authenticate/GetTesisParametre',
  GetRegisterPage = 'authenticate/GetRegisterPage',
  GetRegisterPageBolumler = 'authenticate/GetRegisterPageBolumler',
  CreateRegisterPage = 'authenticate/CreateRegisterPage',
  GetExternalHtmlReport = 'endUserWeb/ExternalHtmlReport/GetExternalHtmlReport',
  GetExternalDuyuruDetay = 'External/Duyurular/GetDuyuruDetay',
  GetExternalDownloadDosyasi = 'External/Duyurular/DownloadDuyuruDosyasi',
  GetOdemeTuru = 'authenticate/GetOdemeTuru',

  /*Eski*/
  OnlineOdemeBitirEski = 'SanalPos/OnlineOdemeBitir',
  OnlineOdemeBitirEskiYK = 'SanalPosYK/SanalPos/OnlineOdemeBitir',
  GetSanalPosBolumHesaplariEski = 'endUserWeb/OnlineOdeme/GetBolumHesaplariV3',
  GetSanalPosBankaBilgileriEski = 'endUserWeb/OnlineOdeme/GetSanalPosBankaBilgileriV3',
  BankayaGidildiIsaretleEski = 'SanalPos/BankayaGidildiIsaretle',
  PayTRBINSorgulaEski = 'SanalPos/PayTRBINSorgula',
  PayTRTaksitOranlariEski = 'SanalPos/PayTRTaksitOranlariV2',
  GetOdenmemisBorcBilgileriAltHesapliEski = 'endUserWeb/OnlineOdeme/GetOdenmemisBorcBilgileriAltHesapliV3',
  GetSanalPosOdemeAltHesapliDurumEski = 'endUserWeb/OnlineOdeme/GetSanalPosOdemeAltHesapliDurumV3',
  GetOdenmemisBorcBilgileri = 'endUserWeb/OnlineOdeme/GetOdenmemisBorcBilgileriV3',
  ExternalOnlineOdemeBitir = 'SanalPos/ExternalOnlineOdemeBitir',
  /*Eski*/

  /*V4*/
  GetOnlineOdemeV4 = 'sanalPos/GetOnlineOdemeV4',
  GetSanalPosBolumHesaplari = 'sanalPos/GetBolumHesaplariV4',
  GetSanalPosBankaBilgileri = 'sanalPos/GetSanalPosBankaBilgileriV4',
  BankayaGidildiIsaretle = 'sanalPos/BankayaGidildiIsaretleV4',
  HareketleriIslePostEdilecekBankaBilgileriGetirV4 = 'sanalPos/HareketleriIslePostEdilecekBankaBilgileriGetirV4',
  PayTRBINSorgula = 'sanalPos/PayTRBINSorgulaV4',
  PayTRTaksitOranlariV2 = 'sanalPos/PayTRTaksitOranlariV4',
  ExternalPayment = 'sanalPos/ExternalPaymentV4',
  GetOdenmemisBorcBilgileriAltHesapli = 'sanalPos/GetOnlineOdemeAltHesapliV4',
  GetSanalPosOdemeAltHesapliDurum = 'sanalPos/GetSanalPosOdemeAltHesapliDurumV4',
  /*V4*/
}
