<div class="container">
  <div class="row">
    <div class="col-md-12">
      <table
        class="table table-border table-striped table-head-fixed projects"
        id="customer-announcement-table"
      >
        <tbody>
          <tr class="bg-transparent">
            <td>
              <div class="post">
                <div class="user-block">
                  <span class="username">
                    <a name="site_yonetim">{{ allData?.duyuru?.tesisAdi }}</a>
                  </span>
                  <span class="description"
                    >{{ allData?.duyuru?.yayinBaslangic | date : "dd.MM.yyyy" }}
                  </span>
                </div>

                <div class="row mb-3">
                  <img
                    [src]="
                      beSafeUrl(
                        allData?.duyuru?.image
                          ? 'data:image/jpeg;base64,' + allData?.duyuru?.image
                          : noPhoto
                      )
                    "
                    alt=""
                    class="mb-15"
                  />
                </div>
                <div
                  class="row mb-3"
                  [innerHtml]="
                    sanitizer.bypassSecurityTrustHtml(
                      allData?.duyuru?.duyuruMetni
                    )
                  "
                ></div>
                <p></p>
                <p>
                  <a
                    *ngFor="let item of allData?.dosyalar"
                    (click)="downloadFile(item)"
                    class="open-file-swal text-primary link-black text-sm mr-2"
                  >
                    <i class="fa fa-link" aria-hidden="true"></i>
                    {{ item.dosyaAdi }}</a
                  >&nbsp;&nbsp;&nbsp;
                </p>
                <p></p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
