import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { environment } from 'src/environments/environment';
import { BrandingService } from '../../../core/services/branding.service';
import { LoginService } from '../login.service';

export enum EInputType {
  email = 1,
  gsm = 2,
}

interface Image {
  src: string;
  title?: string;
  alt?: string;
}

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  passwordForm: FormGroup;
  isLoading = false;
  error: string | undefined;
  brandLogo: string;
  public telNoileDogrula = false;
  dogrulaText = 'Login.TelefonNumarasiIleDogrula';
  public inputType: EInputType = EInputType.email;
  imagesData: Image[] = [];

  customOptions: any = {
    // autoWidth: true,
    loop: true,
    autoHeight: true,
    // lazyLoad: true,
    // lazyLoadEager: 1,
    // animateOut: 'slideOutDown',
    // animateIn: 'flipInX',
    // items: '10',
    // margin: 10,
    // slideBy: 'page',
    // merge: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    // autoplaySpeed: 4000,
    // dotsSpeed: 500,
    dots: false,
    // dotsData: true,
    // mouseDrag: false,
    // touchDrag: false,
    // pullDrag: false,
    // smartSpeed: 400,
    // fluidSpeed: 499,
    // dragEndSpeed: 350,
    // dotsEach: 4,
    center: true,
    URLhashListener: true,
    startPosition: 'URLHash',
    // rewind: true,
    // rtl: true,
    // startPosition: 1,
    // navText: [ '<i class=fa-chevron-left>left</i>', '<i class=fa-chevron-right>right</i>' ],
    responsive: {
      0: {
        items: 1,
      },
    },
    // stagePadding: 40,
    nav: false,
  };
  public APP_LOGO = '';
  public APP_NAME = '';
  isAkMerkez: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private snackBar: SnackBarService,
    private authenticationService: AuthenticationService,
    private brandingService: BrandingService
  ) {
  }

  ngOnInit() {
    this.isAkMerkez = location.origin.indexOf('talep.akmerkez.com.tr') > -1;
    this.passwordForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      gsm: ['', Validators.compose([Validators.required])],
    });
    this.brandingService.init().subscribe(x => {
      this.APP_LOGO = environment.brandLogo;
      this.APP_NAME = environment.appName;
      this.imagesData = environment.sliderImageData;
    });
  }

  dogrulamaKoduGonder() {
    this.isLoading = true;
    const param = {
      inputType: this.inputType,
      input:
        this.inputType === EInputType.email
          ? this.passwordForm.controls.email.value
          : this.passwordForm.controls.gsm.value
            .replace(/[^a-zA-Z0-9 ]/g, '')
            .replace(/\s/g, ''),
      appID: environment.appID,
      domain: environment.domain,
    };

    if (
      (this.inputType === EInputType.email &&
        this.passwordForm.controls.email.valid) ||
      (this.inputType === EInputType.gsm &&
        this.passwordForm.controls.gsm.valid)
    ) {
      this.loginService.sifremiUnuttum(param).subscribe(
        (x: any) => {
          debugger
          this.isLoading = false;
          if (x.state === false) {
            if (x.message === 'kullaniciyok') {
              this.snackBar.open(
                'Login.PasswordKisiYok'.getResultMessage(x.message)
              );
            } else if (x.message === 'fazlakullanici') {
              this.snackBar.open(
                'Login.PasswordFazlaKullanici'.getResultMessage(x.message)
              );
            } else if (x.message === 'gunluksmslimiti') {
              this.snackBar.open(
                'Login.PasswordGunlukSmsLimit'.getResultMessage(x.message)
              );
            } else if (x.message === 'gecersizinput') {
              this.snackBar.open(
                'Login.PasswordGecersizInput'.getResultMessage(x.message)
              );
            } else {
              this.snackBar.open(x.message);
            }
          } else {
            this.snackBar.open(
              'Login.PasswordKodGonderildi'.getResultMessage(x.message)
            );

            this.router.navigate(['/password-refresh'], {
              state: { guid: x.data },
            });
          }
        },
        (error) => {
          this.isLoading = true;
          this.error = error;
          this.snackBar.open('Global.Error');
        }
      );
      // .subscribe((x) => {
      //   if (x == false) {
      //     this.snackBar.open(
      //       'İşlem sırasında hata meydana geldi. Lütfen tekrar deneyiniz.'
      //     );
      //     this.isLoading = false;
      //     return;
      //   }
      //   this.snackBar
      //     .open(
      //       'Şifre yenileme bağlantısı sistemde kayıtlı olan emailinize gönderilecektir.'
      //     )
      //     .then(
      //       () => {
      //         this.isLoading = false;
      //         this.router.navigate(['login']);
      //       },
      //       (error) => {
      //         this.isLoading = false;
      //         this.error = error;
      //       }
      //     );
      // });
    }
  }

  gsmOnKeyupEvent(event: any) {
    const key = event.target.value
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replace(/\s/g, '');
    if (key.length === 1 && key === '0') {
      this.passwordForm.patchValue({ gsm: '' });
    }
  }

  telefonNoileDogrula() {
    this.telNoileDogrula = !this.telNoileDogrula;
    this.dogrulaText =
      this.telNoileDogrula === false
        ? 'Login.TelefonNumarasiIleDogrula'
        : 'Login.EmailIleDogrula';
  }
}
