
// tslint:disable: no-any
/**
 * @hidden
 * reference:
 * @link  https://www.oreilly.com/library/view/regular-expressions-cookbook/9780596802837/ch04s07.html
 */

//export const SENYONET_DATE_API_FORMAT = 'dd.MM.yyyy HH:mm';
export const SENYONET_DATETIME_API_FORMAT = 'DD.MM.YYYY HH:mm';
export const SENYONET_DATE_API_FORMAT = 'DD.MM.YYYY';

const regexDateIso8601 =
  /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[0-1]|0[1-9]|[1-2][0-9])T(2[0-3]|[0-1][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|[+-](?:2[0-3]|[0-1][0-9]):[0-5][0-9])?$/;

const regexEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const regexDigit = /^\d+$/;

function _isUndefined(value: any): boolean {
  return typeof value === 'undefined';
}
function _isNil(value: any): boolean {
  return value === null || typeof value === 'undefined';
}
function _isObject(value: any): boolean {
  return value !== null && typeof value === 'object';
}
function _isNumber(value: any): boolean {
  return typeof value === 'number';
}
function _isString(value: any): boolean {
  return typeof value === 'string';
}
function _isFunction(value: any): boolean {
  return typeof value === 'function';
}
function _isBoolean(value: any): boolean {
  return typeof value === 'boolean';
}
function _isValidIso8601Date(dateString: string): boolean {
  return regexDateIso8601.test(dateString);
}
function _isDateObjValid(date: any): boolean {
  return date != null && date instanceof Date && !isNaN(date.getTime());
}
function _isArray(value: any): boolean {
  return Array.isArray(value);
}

function _firstItem<T>(source: T[]): T | undefined {
  return [].concat(source).shift();
}
function _emailIsValid(email: string): boolean {
  return regexEmail.test(email);
}

function _tcKimlikIsValid(tc: string): boolean {
  if (regexDigit.test(tc) && tc.length === 11 && tc[0] !== '0') {
    const tcNo = tc.split('').map(Number);

    let oddnumberTotal = 0;
    let evenNumberTotal = 0;
    let tenValueTotal = 0;

    tcNo.forEach((x, index) => {
      if (index < 9) {
        if (index % 2 === 0) {
          oddnumberTotal += x;
        } else {
          evenNumberTotal += x;
        }
      }

      if (index < 10) {
        tenValueTotal += x;
      }
    });

    const tenthValueCalc = Math.abs(
      (oddnumberTotal * 7 - evenNumberTotal) % 10
    );
    if (tcNo[9] !== tenthValueCalc) {
      return false;
    }
    const eleventhValueCalc = tenValueTotal % 10;
    if (tcNo[10] !== eleventhValueCalc) {
      return false;
    }

    return true;
  }

  return false;
}

function _turkishtoEnglish(text: string): string {
  text = text ?? '';
  return text
    .replace(/Ğ/gim, 'g')
    .replace(/Ü/gim, 'u')
    .replace(/Ş/gim, 's')
    .replace(/I/gim, 'i')
    .replace(/İ/gim, 'i')
    .replace(/Ö/gim, 'o')
    .replace(/Ç/gim, 'c')
    .replace(/ğ/gim, 'g')
    .replace(/ü/gim, 'u')
    .replace(/ş/gim, 's')
    .replace(/ı/gim, 'i')
    .replace(/ö/gim, 'o')
    .replace(/ç/gim, 'c');
}

function _extractValueFromArray<T, K extends keyof T>(
  source: T[],
  predicate: (item: T) => boolean,
  extractField: K
): T[K] {
  const search = source.find(predicate);
  return Boolean(search) ? search[extractField] : undefined;
}

function _nameInitials(fullnameString: string): string {
  const initials = fullnameString.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
}

function _getProperty(value: { [key: string]: any }, key: string): any {
  if (_isNil(value) || !_isObject(value)) {
    return undefined;
  }
  if (_isNil(key)) {
    return undefined;
  }
  const keys: string[] = key.split('.');
  let result: any = value[keys.shift()];
  for (const k of keys) {
    if (_isNil(result) || !_isObject(result)) {
      return undefined;
    }
    result = result[k];
  }

  return result;
}

function _pluck<T, K extends keyof T>(source: T[], key: K): T[K][] {
  return source.reduce((values, current) => {
    values.push(current[key]);
    return values;
  }, []);
}

function _distinctForKey<T, K extends keyof T>(
  source: T[],
  theField: K
): T[K][] {
  return [...new Set(_pluck(source, theField).filter(Boolean))];
}

function _buildRange(
  range: number,
  startVal: number = 0,
  step: number = 1
): number[] {
  return Array.from({ length: range }, (_, i) => {
    return i * step + startVal;
  });
}

function _chunkify<T>(source: T[], chunkSize: number = 10): T[][] {
  return Array.from(
    { length: Math.ceil(source.length / chunkSize) },
    (_, i) => {
      const start = chunkSize * i;
      return source.slice(start, start + chunkSize);
    }
  );
}

function freeze<T>(obj: T): Readonly<T> {
  return obj as Readonly<T>;
}

const groupBy =
  <T, K extends keyof T>(key: K) =>
    (arraySource: T[]) =>
      arraySource.reduce((acc, cur) => {
        acc[cur[key]] = (acc[cur[key]] || []).concat(cur);
        return acc;
      }, Object.create(null));

function getFirstLast<T>(arraySource: T[]): { first: T; last: T } {
  const { 0: first, length: len, [len - 1]: last } = arraySource;
  return { first, last };
}

const pipe =
  (...fns: any[]) =>
    (x: any) =>
      fns.reduce((acc, curr) => curr(acc), x);
const objToList: <T, K extends keyof T>(obj: T) => T[K][] = (obj) =>
  Object.values(obj);

/**
 * Functional style switchcase
 */
type Value<T> = T | (() => T);
type CaseOfDefault<C, V> = (key: keyof C) => V | (() => V);
type ActionNames<T extends (_: any) => any> = Parameters<T>[0];

const findCaseValue = <C, V>(cases: C, defaultCase: V): CaseOfDefault<C, V> => {
  return ((key: keyof C) => cases[key] || defaultCase) as CaseOfDefault<C, V>;
};

const switchCase = <V, C extends Record<string, Value<V>>>(
  cases: C,
  defaultCase: V
) => {
  return (key: keyof C) => {
    const f = findCaseValue(cases, defaultCase)(key);
    return f instanceof Function ? (f() as V) : f;
  };
};
/*
const removeEmpty = <T>(obj: T): NonNullable<T> =>
  Object.keys(obj)
    .filter((k: string) => obj[k] != null)
    .reduce(
      (newObj, k) =>
        typeof obj[k] === 'object'
          ? { ...newObj, [k]: removeEmpty(obj[k]) }
          : { ...newObj, [k]: obj[k] },
      {}
    ) as NonNullable<T>;
*/
function removeTimeZonePart(date: Date): string {
  let finalDate = '';
  const dateString = date.toISOString();

  if (dateString.split('+').length > 1) {
    const b = dateString.split('+');

    finalDate = b[0];
  } else {
    const b = dateString.split('-');

    if (b.length > 1) {
      b.pop();
      finalDate = b.join('-');
    }
  }

  return finalDate;
}

const indexArrayBy = (
  list: any[] = [],
  keyAccessors: any[] = [],
  multiItem = true,
  flattenKeys = false
) => {
  const keys = (
    keyAccessors instanceof Array
      ? keyAccessors.length
        ? keyAccessors
        : [undefined]
      : [keyAccessors]
  ).map((key) => ({
    keyAccessor: key,
    isProp: !(key instanceof Function),
  }));

  const indexedResult = list.reduce((res, item) => {
    let iterObj = res;
    let itemVal = item;

    keys.forEach(({ keyAccessor, isProp }, idx) => {
      let key;
      if (isProp) {
        const { [keyAccessor]: propVal, ...rest } = itemVal;
        key = propVal;
        itemVal = rest;
      } else {
        key = keyAccessor(itemVal, idx);
      }

      if (idx + 1 < keys.length) {
        if (!iterObj.hasOwnProperty(key)) {
          iterObj[key] = {};
        }
        iterObj = iterObj[key];
      } else {
        // Leaf key
        if (multiItem) {
          if (!iterObj.hasOwnProperty(key)) {
            iterObj[key] = [];
          }
          iterObj[key].push(itemVal);
        } else {
          iterObj[key] = itemVal;
        }
      }
    });
    return res;
  }, {});

  // if ((multiItem as any) instanceof Function) {
  //   // Reduce leaf multiple values
  //   (function reduce(node, level = 1) {
  //     if (level === keys.length) {
  //       Object.keys(node).forEach(k => (node[k] = multiItem(node[k])));
  //     } else {
  //       Object.values(node).forEach(child => reduce(child, level + 1));
  //     }
  //   })(indexedResult); // IIFE
  // }

  let result = indexedResult;

  if (flattenKeys) {
    // flatten into array
    result = [];

    (function flatten(node, accKeys: any[] = []): void {
      if (accKeys.length === keys.length) {
        result.push({
          keys: accKeys,
          vals: node,
        });
      } else {
        Object.entries(node).forEach(([key, val]) =>
          flatten(val, [...accKeys, key])
        );
      }
    })(indexedResult); // IIFE

    if (
      keyAccessors instanceof Array &&
      keyAccessors.length === 0 &&
      result.length === 1
    ) {
      // clear keys if there's no key accessors (single result)
      result[0].keys = [];
    }
  }

  return result;
};

declare global {
  export interface String {
    getResultMessage(res: string): string;
  }
}

String.prototype.getResultMessage = function (res: string): string {
  if (res!.charAt(0) == '@') {
    return res.substr(1);
  } else {
    return this;
  }
}

function createGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
function _isUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
function _formatTL(val: number) {
  return this.currency.transform(val, "TRY", 'symbol', '1.2-2');
}

export const SenyonetUtils = {
  isNumber: _isNumber,
  isArray: _isArray,
  isUndefined: _isUndefined,
  isString: _isString,
  isFunction: _isFunction,
  isBoolean: _isBoolean,
  firstItem: _firstItem,
  getSingleValue: _extractValueFromArray,
  isValidEmail: _emailIsValid,
  isValidIsoDate: _isValidIso8601Date,
  nameInitials: _nameInitials,
  getProperty: _getProperty,
  getUniqeForKey: _distinctForKey,
  pluck: _pluck,
  isValidDateObj: _isDateObjValid,
  rangeArray: _buildRange,
  divideArray: _chunkify,
  isValidTcKimlik: _tcKimlikIsValid,
  turkishtoEnglish: _turkishtoEnglish,
  groupBy,
  getFirstLast,
  pipe,
  toList: objToList,
  //  removeEmpty,
  removeTimeZonePart,
  createGuid,
  isUrl: _isUrl,
  formatTL: _formatTL
};
