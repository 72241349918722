<ng-template #template>
  <section
    fxLayout="column wrap"
    fxLayoutGap="60px"
    fxLayoutAlign="center center"
  >
    <div fxFlex>
      {{ "OnlinePayment.ResultTitle" | translate }}
    </div>
    <div fxFlex>
      {{ odemeSonucAciklama }}
    </div>
    <div fxFlex>
      <button
        #submitButton
        class="button"
        (click)="redirect()"

      >
        <span class="submit">{{ "Global.Anasayfa" | translate }}</span>
        <span class="loading"><i class="fa fa-refresh"></i></span>
        <span class="check"><i class="fa fa-check"></i></span>
      </button>
    </div>
  </section>
</ng-template>
