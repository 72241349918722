<div *ngIf="isRendered">
  <form
    #bankaSubmitForm
    ngNoForm
    [action]="bankaSubmitData.bankaBilgileri.postURL"
    method="post"
    [formGroup]="form"
    *ngIf="!iyzico"
  >
    <div *ngFor="let field of fields">
      <input hidden [name]="field.key" [type]="field.key == 'installment_count' ? 'number':'text'" [formControlName]="field.key" />
    </div>
    <button hidden type="submit" [disabled]="!form.valid"></button>
  </form>
  <div id="sametIyzico" class="hide-form" [innerHtml]="iyzicoHtmlContent"></div>
</div>
<!-- {{fields | json}} -->
