import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@core/communication';
import { LoadingService } from '@core/services/loading.service';
import { tap } from 'rxjs/operators';
import { ApiUrls } from 'src/app/api-urls.enum';
import { IDuyuruDosyasi, IDuyuruResult } from './models/index';

@Component({
  selector: 'app-external-duyuru',
  templateUrl: './external-duyuru.component.html',
  styleUrls: ['./external-duyuru.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalDuyuruComponent implements OnInit {
  @ViewChild("duyuruContent") duyuruIcerik: ElementRef;
  public allData: IDuyuruResult;
  noPhoto = '/assets/img/no-picture.jpg';
  guid: string = '';
  constructor(private dataService: DataService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((x: any) => {
      //debugger;
      this.guid = x.params.guidLink
      console.log(this.guid);
      this.getData();
    });
  }

  beSafe(value: string) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  beSafeUrl(value: string) {
    return this.sanitizer.bypassSecurityTrustUrl(value);
  }
  getData() {
    const param = {
      guid: this.guid
    };
    this.dataService.get<IDuyuruResult>(ApiUrls.GetExternalDuyuruDetay, param).pipe(
      tap((x) => {
        if (!x.result) {
          alert('Veri yüklenirken bir hata oluştu!');
        } else {
          this.allData = x;
          this.cdr.detectChanges();
        }
      })
    ).subscribe();
  }
  downloadFile(item: IDuyuruDosyasi) {
    const param = {
      guid: this.guid,
      dosyaID: item.dosyaID
    };
    this.dataService.post<IDuyuruResult>(ApiUrls.GetExternalDownloadDosyasi, param).pipe(tap(x => {
      if (x.result) {
        this.download(x);
      } else {
        alert("");
      }
    })).subscribe();
  }

  download(item: any): void {
    const source = `data:${item.file.contentType};base64,${item.file.dosya}`;
    const byteCharacters  = atob(item.file.dosya);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: item.file.contentType});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${item.file.dosyaAdi}`;
    link.click();
  }
}
