<div class="d-flex flex-stack mb-5 justify-content-end">
  <button
    mat-icon-button
    matTooltip="{{ 'Global.Close' | translate }}"
    (click)="dismiss()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content align="start">
  <p [innerHTML]="sozlesme.degeri"></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="btn btn-success" (click)="accept()">
    {{ "Auth.Terms.Accept" | translate }}
  </button>
</mat-dialog-actions>
