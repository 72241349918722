<link
  href="https://kendo.cdn.telerik.com/2020.1.114/styles/kendo.common.min.css"
  rel="stylesheet"
/>
<link
  href="https://kendo.cdn.telerik.com/2020.1.114/styles/kendo.blueopal.min.css"
  rel="stylesheet"
/>
<div style="height: 80vh">
  <tr-viewer
    #viewer1
    [containerStyle]="viewerContainerStyle"
    [serviceUrl]="serviceUrl"
    [reportSource]="{
      report: reportName,
      parameters: parameters
    }"
    [viewMode]="'INTERACTIVE'"
    [scaleMode]="'FIT_PAGE'"
    [pageMode]="'CONTINUOUS_SCROLL'"
    [scale]="1.0"
    [authenticationToken]="token"
    [ready]="removeItems"
  >
  </tr-viewer>
</div>

