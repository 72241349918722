import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CredentialsService } from '../../../core/credentials.service';

@Component({
  selector: 'app-odeme-sonuc-yeni',
  templateUrl: './odeme-sonuc.component.html',
  styleUrls: ['./odeme-sonuc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OdemeSonucComponent implements OnInit, AfterViewInit {
  odemeSonucAciklama: any;
  iframeUrl: string;
  odemeDurum: boolean;
  @ViewChild('template', { static: true }) templateRef: TemplateRef<any>;
  @ViewChild('submitButton', { static: false }) submitButton: ElementRef<HTMLButtonElement>;
  isClicked: boolean = false;
  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private credentialService: CredentialsService,
    private elementRef: ElementRef,
    private http: HttpClient
  ) {
    this.odemeSonucAciklama = this.route.snapshot.paramMap.get('aciklama');
    this.route
      .data
      .subscribe(v => {
        this.odemeDurum = v.durum;
        this.odemeSonucAciklama = this.odemeDurum == true ? this.translateService.instant('OnlinePayment.OdemeYaptiginizIcinTesekkurEderiz') : this.odemeSonucAciklama;
      });
  }
  ngAfterViewInit(): void {
    const btn = this.submitButton.nativeElement;
    btn.addEventListener('transitionend', this.toggleClass.bind(this));
    btn.addEventListener('transitionend', this.addClass.bind(this));
  }
  ngOnInit() {
    if (this.odemeSonucAciklama) {
      this.odemeSonucDialogOpen();
    }
  }

  odemeSonucDialogOpen(): void {

    const dialogRef = this.dialog
      .open(this.templateRef, {
        width: '100%',
        maxWidth: '480px',
        data: this.odemeSonucAciklama,
        disableClose: true
      });
  }
  toggleClass(event: any) {
    const btn = event.srcElement;
    btn.classList.add('active');
  }
  addClass(event: any) {
    const btn = event.srcElement;
    btn.classList.add('finished');
  }
  startIterval() {
    const timeOut = setTimeout(() => {
      window.location.reload();
      clearTimeout(timeOut);
    }
      , 5000);
  }
  redirect() {
    if (this.isClicked == false) {
      this.startIterval();
    }
    this.isClicked = true;
    const btn = this.submitButton.nativeElement;
    btn.classList.add("active");
    if (window.location !== window.parent.location) {
      try {
        window.top.postMessage(
          { for: 'odemeSonuc', data: 'anything' },
          "*"
        );
      } catch (error) {
        console.log("olmadı 1");
      }

      try {
        window.parent.window.postMessage(
          { for: 'odemeSonuc', data: 'anything' },
          "*"
        );
      } catch (error) {
        console.log("olmadı 2");
      }
      try {
        window.top.postMessage(
          { for: 'odemeSonuc', data: 'anything' },
          "*"
        );
      } catch (error) {
        console.log("olmadı 3");
      }
      try {
        window.parent.window.postMessage(
          { for: 'odemeSonuc', data: 'anything' },
          "*"
        );
      } catch (error) {
        console.log("olmadı 4");
      }
      try {
        window.top.postMessage(
          { for: 'odemeSonuc', data: 'anything' },
          "*"
        );
      } catch (error) {
        console.log("olmadı 5");
      }
      try {
        window.parent.window.postMessage(
          { for: 'odemeSonuc', data: 'anything' },
          "*"
        );
      } catch (error) {
        console.log("olmadı 6");
      }
    } else {
      this.router.navigate(['/online-odeme-yeni']);
    }
  }
}
