import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { forkJoin, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ApiUrls } from '../api-urls.enum';
import { DataService } from '../core/communication/rest-service-invoker.service';
import { CredentialsService } from '../core/credentials.service';
import { LoadingService } from '../core/services/loading.service';
import { KeDosyaDetay, KeDosyalar } from './models/ke-dosyalar.model';

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  constructor(
    private dataService: DataService,
    private media: MediaObserver,
    private credentialService: CredentialsService,
    private loadingService: LoadingService) { }

  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }

  private _iframeParentUrl: string = '';
  public get IframeParentUrl(): string {
    return this._iframeParentUrl;
  }
  public set IframeParentUrl(url: string) {
    this._iframeParentUrl = url;
  }

  getKEDosylar(dokumanID: number, tabloAdi: string, getFile: boolean) {
    const param = this.credentialService.getAllCredential();
    param.dokumanID = dokumanID;
    param.tabloAdi = tabloAdi;
    param.getFile = getFile;
    return this.dataService.get<KeDosyalar[]>('endUserWeb/Common/GetKEDosyalarV3', param);
  }

  getKEDosyaDetay(dokumanID: number, dosyaID: number) {
    return this.dataService.get<KeDosyaDetay>('endUserWeb/Common/GetKEDosyaDetay', {
      dokumanID,
      dosyaID,
    });
  }

  downloadFile(dosya: KeDosyaDetay): void {
    const source = `data:${dosya.contentType};base64,${dosya.dosya}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${dosya.dosyaAdi}`;
    link.click();
  }

  downloadFileByService(dokumanID: number, dosyaID: number): void {
    this.getKEDosyaDetay(dokumanID, dosyaID).subscribe((dosya) => {
      this.downloadFile(dosya);
    });
  }

  getImageFiles(dokumanID: number, tabloAdi: string) {
    return this.getKEDosylar(dokumanID, tabloAdi, true).pipe(
      map((dosyalar) => {
        return dosyalar.filter((dosya) => dosya.contentType.includes('image'));
      }),
      mergeMap((dosyalar: KeDosyalar[]) => {
        const joinedObs = dosyalar.map((d) =>
          this.getKEDosyaDetay(dokumanID, d.dosyaID)
        );
        return forkJoin(joinedObs);
      })
    );
  }

  getIconByContentType(contentType: string): string {

    const findTerm = (term: string) => {
      if (contentType.includes(term)) {
        return contentType;
      }
    };
    let icon = '';
    switch (contentType) {
      case 'application/pdf':
        icon = 'picture_as_pdf';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        icon = 'word';
        break;
      case 'application/msword':
        icon = 'word';
        break;
      case 'application/vnd.ms-excel':
        icon = 'excel';
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        icon = 'power point';
        break;
      case 'text/plain':
        icon = 'text';
        break;
      case findTerm('image'):
        icon = 'image';
        break;
      case findTerm('compressed'):
        icon = 'zip';
        break;

      default:
        icon = 'default';
        break;
    }
    return icon;
  }

  getKullaniciKVKKMetni(param: any): Observable<{ metin: string }> {
    return this.dataService.get<{ metin: string }>(
      'endU-global/GetKullaniciKVKKMetniV3', param
    );
  }
  kvkkOnayUpdate(param: any): Observable<boolean> {
    return this.dataService.post<boolean>(
      'endU-global/KullaniciKVKKOnayGuncelleV3', param
    );
  }
  getTesisParametre(param: any): Observable<string> {
    return this.dataService.post<string>(ApiUrls.GetTesisParametre, param);
  }
  getOdemeTuru(param: any): Observable<any> {
    return this.dataService.post<any>(ApiUrls.GetOdemeTuru, param);
  }


}
