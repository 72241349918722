import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-odeme-sonuc',
  templateUrl: './odeme-sonuc.component.html',
  styleUrls: ['./odeme-sonuc.component.scss'],
})
export class OdemeSonucModalComponent implements OnInit {
  aciklama: string;
  constructor(
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<OdemeSonucModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.aciklama = data;
  }

  ngOnInit() {

    setTimeout(() => {
      this.closeDialog(true);
    }, 5000);
   }

  closeDialog(durum: boolean): void {

    this.dialogRef.close(durum);
    window.location.reload();
  }
  sendMessage(){

    const iframeUrl = localStorage.getItem('IframeParentUrl');
    window.parent.window.postMessage(
      { for: 'odemeSonuc', data: 'anything' },
      "*"
    );
  }
}
