<ng-template #template>
  <section fxLayout="column wrap" fxLayoutGap="60px" fxLayoutAlign="center center">
    <div fxFlex>
      {{ "Title.VisitorEnter" | translate }}
    </div>
  </section>
</ng-template>

  <div class="d-flex flex-row-fluid w-100 h-100 order-1 order-2 bg-primary">
    <span
    [inlineSVG]="'assets/misc/pattern-2.svg'"
    class="svg-illustrator position-absolute w-100 h-100 end-0"
  ></span>
  <div
  class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100 z-index-3"
  >
  <a class="mb-2 mb-lg-10">
    <img
      alt="Logo"
      src="/assets/logos/logo-text-on-dark.svg"
      class="h-20px h-lg-35px"
    />
  </a>

    <mat-label class="fs-1 text-light fw-bold text-center my-3">
      {{ "ZiyaretciGirisi.ScanQRLogin" | translate }}
    </mat-label>
    <mat-label *ngIf="qrCode" class="fs-2 text-light fw-bold text-center my-3 my-lg-7">
      {{ "ZiyaretciGirisi.ScanQR" | translate }}
    </mat-label>
    <div class="w-325px h-325px bg-white position-relative p-3 rounded-4">
      <ng-container *ngIf="!qrCode">
        <div class="h-100 w-100 d-flex justify-content-center align-items-center flex-column gap-10">
          <mat-icon [inline]="true" class="fs-10tx d-flex w-50px h-50px align-items-center justify-content-center text-danger">error_outline</mat-icon>
          <mat-label class="fs-1 text-black fw-bold text-center mt-3 mt-lg-7">
            {{ "ZiyaretciGirisi.InvalidQrCodeRequest" | translate }}
          </mat-label>
        </div>
        
      </ng-container>
      <ng-container *ngIf="qrCode">
        <!-- <div class="progress w-100" role="progressbar" aria-label="Basic example" aria-valuenow="timeLeft" aria-valuemin="0" aria-valuemax="100">
          <div class="progress-bar bg-info" [ngStyle]="{'width.%': ((timeLeft / initialTime)) * 100}" #progressBar></div>
        </div> -->
        <qrcode [qrdata]="qrCode" [width]="300" [errorCorrectionLevel]="'M'" [cssClass]="'qrcode'"></qrcode>
        <!-- <div class="overlay">
          <div class="button" *ngIf="timeLeft <= 0" (click)="refreshBarcode()">
            <mat-icon>replay</mat-icon>
            {{'ZiyaretciGirisi.QRCodeReload' | translate}}
          </div>
        </div> -->
      </ng-container>
     
    </div>

  </div>
  </div>

<!-- <div class="row" *ngIf="!qrCode">
  <mat-label>
    {{ "ZiyaretciGirisi.InvalidQrCodeRequest" | translate }}
  </mat-label>
</div>

<div fxFlex>
  cfgcfgc
  <qrcode *ngIf="qrCode" [qrdata]="qrCode" [width]="500" [errorCorrectionLevel]="'M'">
  </qrcode>
</div> -->

