import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { AngularFormsInputMasksModule } from 'angular-forms-input-masks';
import { InlineSVGModule } from 'ng-inline-svg';
import { SlideshowModule } from 'ng-simple-slideshow';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MaterialModule } from '../material.module';
import { BankaSubmitFormComponent } from './components/banka-submit-form/banka-submit-form.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ExternalRedirecterComponent } from './components/external-redirecter/external-redirecter.component';
import { FileListComponent } from './components/file-list/file-list.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { KvkkOnayComponent } from './components/kvkk-onay/kvkk-onay.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { OdemeSonucModalComponent } from './components/odeme-sonuc/odeme-sonuc.component';
import { ReportViewerComponent } from './components/report-viewer/report-viewer.component';
import { VirtualPosTermsComponent } from './components/virtual-pos-terms/virtual-pos-terms.component';
import { DebounceClickDirective } from './directives/debounceClick.directive';
import { LoaderComponent } from './loader/loader.component';
import { LoadingComponent } from './loading.component';
import { MatPaginationIntlService } from './matPagination-Intl-service';
import { CallBackPipe } from './pipes/callback.pipe';
import { KeysPipe } from './pipes/keys-pipe';
import { SafePipe } from './pipes/safe.pipe';
import { WidgetComponent } from './widget/widget.component';

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    TranslateModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    SlideshowModule,
    InlineSVGModule,
    CarouselModule,
    ReactiveFormsModule,
    AngularFormsInputMasksModule,
    TelerikReportingModule,
    NgbModule,
  ],
  declarations: [
    LoaderComponent,
    WidgetComponent,
    KeysPipe,
    SafePipe,
    CallBackPipe,
    KvkkOnayComponent,
    FileListComponent,
    BankaSubmitFormComponent,
    OdemeSonucModalComponent,
    ImagePreviewComponent,
    ReportViewerComponent,
    NotFoundPageComponent,
    DebounceClickDirective,
    ConfirmComponent,
    LoadingComponent,
    ExternalRedirecterComponent,
    VirtualPosTermsComponent
  ],
  exports: [
    LoaderComponent,
    TranslateModule,
    MaterialModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    SlideshowModule,
    CarouselModule,
    HttpClientModule,
    WidgetComponent,
    KeysPipe,
    SafePipe,
    CallBackPipe,
    InlineSVGModule,
    AngularFormsInputMasksModule,
    FileListComponent,
    BankaSubmitFormComponent,
    OdemeSonucModalComponent,
    ImagePreviewComponent,
    ReportViewerComponent,
    NotFoundPageComponent,
    TelerikReportingModule,
    DebounceClickDirective,
    NgbModule,
    ConfirmComponent,
    LoadingComponent,
    VirtualPosTermsComponent
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginationIntlService,
    },
  ],
})
export class SharedModule { }
