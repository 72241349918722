import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BrandingService } from 'src/app/core/services/branding.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login.service';
import { Bloklar } from '../models/bloklar.model';
import { Bolumler } from '../models/bolumler.model';
import { MulkiyetDurum } from '../models/mulkiyet-durum.model';
import { TesisBilgileri } from '../models/tesis-bilgileri.model';

interface Image {
  src: string;
  title?: string;
  alt?: string;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm!: FormGroup;

  bloklar: Bloklar[];
  bolumler: Bolumler[];
  mulkiyetDurumlari: MulkiyetDurum[];
  tesisBilgileri: TesisBilgileri;
  tesisLogo: string;
  submit = false;
  isLoading = false;

  imagesData: Image[] = [];
  loadSlider = false;

  public APP_LOGO = '';
  public APP_NAME = '';
  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private actr: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private snackBar: SnackBarService,
    private brandingService: BrandingService,
    private translateService: TranslateService
  ) {
    const resolverData = this.actr.snapshot.data.register;
    this.mulkiyetDurumlari = resolverData.mulkiyetDurumlari;
    this.bloklar = resolverData.bloklar;
    this.tesisBilgileri = resolverData.tesisBilgileri;
    this.createForm();
    this.brandingService.init().subscribe(x => {
      this.APP_LOGO = environment.brandLogo;
      this.APP_NAME = environment.appName;
      this.imagesData = environment.sliderImageData;
      this.loadSlider = true;
    });
    if (this.tesisBilgileri === null) {
      this.router.navigate(['/login']);
    }
  }

  customOptions: any = {
    loop: true,
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    dots: false,
    center: true,
    URLhashListener: true,
    startPosition: 'URLHash',
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  ngOnInit(): void {
    this.tesisLogo =
      'data:' +
      this.tesisBilgileri.logoDosyaTuru +
      ';base64,' +
      this.tesisBilgileri.logo;
  }

  ngOnDestroy(): void {
    this.isLoading = false;
  }

  setStatus(status: boolean): void {
    this.isLoading = status;
    this.submit = status;
  }
  register() {
    try {

      this.setStatus(true);
      const form = this.registerForm.value;

      form.telNo = form.telNo
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .replace(/\s/g, '');
      form.cepTelNo = form.cepTelNo
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .replace(/\s/g, '');
      if (form.blokID === '' || form.bolumID === '' || form.mulkiyetDurumID === '') {
        this.setStatus(false);
        return;
      }
      if (
        form.sifre !==
        form.sifreTekrar
      ) {
        this.snackBar.open('Requests.BelirtmisOldugunuzSifrelerBirbirindenFarklidir');
        this.setStatus(false);
        return;
      }

      this.loginService.createRegisterPage(this.registerForm.value).subscribe(
        (x) => {
          if (x.state === true) {
            this.snackBar
              .open(
                'Requests.UyelikTalebinizBasariliBirSekildeAlinmistirAktivasyonIslemindenSonraSizeGelenOnayMailiIleBeraberSistemeGirisYapabilirsiniz'
              )
              .then((x) => {
                this.router.navigateByUrl('login');
              });
          } else if (x.state === false && x.message === 'user_exist') {
            this.snackBar.open(
              'Requests.GirmisOldugunuzEmailAdresineAitBirUyelikZatenBulunmaktadırSifremiHatirlatKisminiKullanarakMevcutUyeliginizIleGirisYapabilirsiniz'
            );
          } else {
            this.snackBar.open('Requests.HataOlustu');
          }
          this.setStatus(false);
        },
        (error) => {
          this.setStatus(false);
          this.snackBar.open('Requests.HataOlustu');
        }
      );
    } catch (error) {
      this.setStatus(false);
    }
  }

  private createForm(): void {
    this.registerForm = this.formBuilder.group({
      adi: ['', Validators.required],
      soyadi: ['', Validators.required],
      tcKimlikNo: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(11)]),
      ],
      eposta: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          //Validators.maxLength(11)
        ]),
      ],
      cepTelNo: ['', Validators.required],
      telNo: ['', Validators.required],
      blokID: ['', Validators.required],
      bolumID: ['', Validators.required],
      mulkiyetDurumID: ['', Validators.required],
      sifre: ['', Validators.required],
      sifreTekrar: ['', Validators.required],
      tesisID: [this.tesisBilgileri.tesisID, Validators.required],
    });
  }

  blokChange(event: any): void {
    this.loginService
      .getRegisterPageBolumler(this.tesisBilgileri.tesisID, event.value)
      .subscribe((x) => {
        this.bolumler = x;
        this.registerForm.patchValue({ bolumID: this.bolumler[0].bolumID });
      });
  }

  goBack(): void {
    this.location.back();
  }

  log() {

  }
}
