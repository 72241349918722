import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TesisBolumler } from '../senyonet-musteri/home/models';
import { ITesisParametre } from '../shared/models';
import { DataService } from './communication';
import { FacilityService } from './facility.service';
import { Credentials } from './models/credentials.model';

const credentialsKey = 'senyonet-user';
const tesisParametreKey = 'senyonet-parameters';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  // tslint:disable-next-line: variable-name
  private _credentials: Credentials | null = null;
  private _tesisParametreleri: ITesisParametre[] | null = null;
  constructor(private facilityService: FacilityService, private dataService: DataService) {
    const savedCredentials =
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      this.facilityService.tesisID = this._credentials.varsayilanTesisID.toString();
      this.facilityService.bolumID = this._credentials.bolumID?.toString();
      this.facilityService.blokID = this._credentials.blokID?.toString();
      this.facilityService.firmaID = this._credentials.firmaID?.toString();
      this.facilityService.firmaKisiID = this._credentials.firmaKisiID?.toString();
      this.facilityService.adiSoyadi = this._credentials.adiSoyadi?.toString();
    }

    const params = localStorage.getItem(tesisParametreKey);
    if (params) {
      this._tesisParametreleri = JSON.parse(params);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    // TODO
    // Token Expire Check

    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }
  get tesisParametreleri(): ITesisParametre[] | null {
    return this._tesisParametreleri;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean): void {
    this._credentials = credentials || null;
    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      // if (credentials.manuel === false) {
      //   const tesis = this.credentials.kullaniciTesisleri[0];
      //   credentials.varsayilanTesisID = tesis.tesisID;
      //   credentials.varsayilanTesisAdi = tesis.tesisAdi;
      // } else {
      const tesis = this.credentials.kullaniciTesisleri.find(
        (x) => x.tesisID === credentials.varsayilanTesisID
      );
      credentials.varsayilanTesisID = tesis.tesisID;
      credentials.varsayilanTesisAdi = tesis.tesisAdi;
      // }
      storage.setItem(credentialsKey, JSON.stringify(credentials));
      this.facilityService.tesisID =
        this._credentials.varsayilanTesisID.toString();
      this.facilityService.firmaID = this._credentials.firmaID?.toString();
      this.facilityService.bolumID = this._credentials.bolumID?.toString();
      this.facilityService.blokID = this._credentials.blokID?.toString();
      this.facilityService.firmaKisiID =
        this._credentials.firmaKisiID?.toString();
      this.facilityService.adiSoyadi = this._credentials?.adiSoyadi ?? "";
      this.getTesisParametreleri();
    } else {
      this.facilityService.tesisID = '';
      this.facilityService.bolumID = '';
      this.facilityService.blokID = '';
      this.facilityService.firmaID = '';
      this.facilityService.firmaKisiID = '';
      this.facilityService.adiSoyadi = '';
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }
  clearFix(bolumler: TesisBolumler[]) {

    const tesisler = this._credentials.kullaniciTesisleri;
    let i = 0;
    tesisler.forEach((element) => {
      const index = bolumler.findIndex((x) => x.tesisID === element.tesisID);
      if (index === -1) {
        tesisler.splice(i, 1);
      }
      i++;
    });

    this._credentials.kullaniciTesisleri = tesisler;
    this.setCredentials(this._credentials);
  }

  getAllCredential() {
    const user = {
      kullaniciID: this._credentials?.kullaniciID ?? 0,
      personelID: 0,
      tesisID: this._credentials?.tesisID ?? 0,
      blokID: this._credentials?.blokID ?? 0,
      bolumID: this._credentials?.bolumID ?? 0,
      firmaID: this._credentials?.firmaID ?? 0,
      firmaKisiID: this._credentials?.firmaKisiID ?? 0,
      hesapID: this._credentials?.cariHesapID ?? 0,
      dilID: this._credentials?.dilID ?? 0,
      dilKodu: this._credentials?.dilKodu ?? '',
      refererUrl: window.location.host,
      appID: environment.appID ?? 'erhlgfıerhıguhruıhger',
      domain: environment.domain ?? 'ergkjerjkghejr',
      mulkiyetDurumID: this._credentials?.mulkiyetDurumID ?? 0
    } as any;
    return user;
  }

  getTesisParametreleri() {
    const param = this.getAllCredential();
    this.dataService.get<ITesisParametre[]>('shared/GetParameters', param).subscribe(x => {
      localStorage.setItem(tesisParametreKey, JSON.stringify(x));
      this._tesisParametreleri = x;
    });
  }
}
