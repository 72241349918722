export const environment = {
  production: true,

  brandLogoLight: 'assets/svg/logo.svg',
  brandLogoDark: 'assets/svg/logo.svg',
  serverUrl: 'https://gateway.senyonet.net/api/',
  //  serverUrl: 'https://testapi.senyonet.net/api/',
  // serverUrl: 'https://ssmgw.issturkiye.com/api/',
  // serverUrl: 'http://localhost:5000/api/',
  defaultLanguage: 'tr-TR',
  brandLogo: 'assets/svg/logo.svg',
  insideLogo: 'assets/svg/logo.svg',
  favIcon: 'assets/svg/logo.svg',
  supportedLanguages: [
    'en-US',
    'tr-TR',
    'ru-RU',
    'ar-AR',
    'uz-UZ'
  ],
  isTeknik: true,
  dateFormat: 'DD.MM.YYYY',
  dateTimeFormat: 'DD.MM.YYYY HH:mm',
  appID: '',//net.senyonet
  appName: '',
  domain: 'https://portal.senyonet.net/',
  webType: 1,
  sliderImageData: [],
  urunID: 1
};
