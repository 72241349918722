import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiUrls } from 'src/app/api-urls.enum';
import { DataService } from '../../../core/communication/rest-service-invoker.service';
import { IStatusModel } from '../../../shared/models/IStatusModel';
import { IExternalResult } from './models/IExternalResult';

@Injectable({
  providedIn: 'root',
})
export class ExternalPaymentService {
  constructor(private dataService: DataService) { }

  private musteriGrupID$: Subject<number> = new Subject<number>();
  nextMusteriGrupID(data: number) {
    this.musteriGrupID$.next(data);
  }
  getMusteriGrupID(): Observable<number> {
    return this.musteriGrupID$.asObservable();
  }

  getExernalPageData(
    guidLink: string,
    dilKodu: string
  ): Observable<IExternalResult> {
    return this.dataService.get<IExternalResult>(ApiUrls.ExternalPayment, {
      guidLink,
      dilKodu,
    });
  }
  getExernalHtmlReport(guidLink: string): Observable<IStatusModel> {
    return this.dataService.get<IStatusModel>(ApiUrls.GetExternalHtmlReport, {
      guid: guidLink,
    });
  }
  onlineOdemeBitir(param: any) {
    return this.dataService.post<any>(ApiUrls.ExternalOnlineOdemeBitir, param);
  }
}
