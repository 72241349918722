import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { SenyonetUtils, SENYONET_DATE_API_FORMAT } from '../utilities/utils';
// import { Configuration } from '../../configuration/services/configuration.services';

/* Generic bir rest api consumer... */
@Injectable({ providedIn: 'root' })
export class DataService {
  /* T is a generic for ErpApp domain entities... */

  constructor(
    private http: HttpClient // private _configuration: Configuration
  ) {}

  public get<T>(url: string, param?: any): Observable<T> {

    const actionUrl: string = /*this._configuration.ServerWithAPiUrl +*/ url;
    let params = new HttpParams();

    if (param) {
      // Begin assigning parameters
      Object.entries(param).forEach(([key, value]) => {
        const v =
          value === null || value === undefined
            ? ''
            : SenyonetUtils.isValidDateObj(value)
            ? format(value as Date, SENYONET_DATE_API_FORMAT)
            : value.toString();
        params = params.append(key, v);
      });
    }
    return this.http.get<T>(actionUrl, { params });
  }

  public post<T>(url: string, param?: any): Observable<T> {
    const actionUrl: string = /*this._configuration.ServerWithAPiUrl +*/ url;
    return this.http.post<T>(actionUrl, param);
  }

  public put<T>(url: string, param: any): Observable<T> {
    const actionUrl: string = /*this._configuration.ServerWithAPiUrl +*/ url;
    return this.http.put<T>(actionUrl, param);
  }

  /* public update<T>(id: number, itemToUpdate: any): Observable<T> {
      return this.http.put<T>(this.actionUrl + id, JSON.stringify(itemToUpdate));
  } */

  public delete<T>(url: string, id: number): Observable<T> {
    const actionUrl: string = /*this._configuration.ServerWithAPiUrl +*/ url;
    return this.http.delete<T>(actionUrl + id);
  }
}
