import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CredentialsService } from '@core/credentials.service';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-kvkk-onay',
  templateUrl: './kvkk-onay.component.html',
  styleUrls: ['./kvkk-onay.component.scss']
})
export class KvkkOnayComponent implements OnInit {
  kvkkForm: FormGroup;
  kvkkMetin = '';
  constructor(public dialogRef: MatDialogRef<KvkkOnayComponent>,
    private sharedService: SharedService,
    private credentialService: CredentialsService) {

    this.kvkkForm = new FormGroup({
      kvkkOnay: new FormControl(false)
    })
  }

  ngOnInit(): void {
    this.sharedService.getKullaniciKVKKMetni(this.credentialService.getAllCredential()).subscribe((x) => {
      this.kvkkMetin = x.metin;
    });
  }

  onayKaydet(): void {

    if (this.kvkkForm.value.kvkkOnay) {
      this.sharedService.kvkkOnayUpdate(this.credentialService.getAllCredential()).subscribe(() => {
        this.closeDialog(true);
      });
    }
  }

  closeDialog(onay: boolean): void {
    this.dialogRef.close(onay);
  }

}
