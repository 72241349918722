<div class="mb-3" fxLayoutAlign="space-between center" >
  <h3 class="m-0" mat-dialog-title>{{ 'KVKKOnayDialog.Title' | translate }}</h3>
  <button mat-icon-button (click)="closeDialog(false)">
  <mat-icon>close</mat-icon>
</button>
</div>
<mat-dialog-content [formGroup]="kvkkForm">

  <div [innerHTML]="kvkkMetin"></div>
    <input type="checkbox" name="kvkkOnay" id="kvkk-checkbox" formControlName="kvkkOnay"/>{{ 'KVKKOnayDialog.Onayliyorum' | translate }}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button  [ngClass]="{'bg-primary text-white' : !kvkkForm.value.kvkkOnay}" [disabled]="!kvkkForm.value.kvkkOnay" class="btn-green" (click)="onayKaydet()">{{ 'Global.Save' | translate }}</button>
  <button mat-stroked-button (click)="closeDialog(false)">{{ 'Global.Cancel' | translate }}</button>
</mat-dialog-actions>

