import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { IMenuItem } from './layout.model';
import { LayoutService } from './layout.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutResolver implements Resolve<IMenuItem[]> {
  constructor(private layoutService: LayoutService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): IMenuItem[] | import('rxjs').Observable<IMenuItem[]> | Promise<IMenuItem[]> {
    return this.layoutService.userMenus();
  }
}
