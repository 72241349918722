import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { finalize, map, mergeMap } from 'rxjs/operators';
import { Logger } from 'src/app/core';
import { untilDestroyed } from 'src/app/core/custom-rxjs-operators';
import { BrandingService } from 'src/app/core/services/branding.service';
import { KvkkOnayComponent } from 'src/app/shared/components/kvkk-onay/kvkk-onay.component';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../core/auth/authentication.service';

const log = new Logger('Login');
interface Image {
  src: string;
  title?: string;
  alt?: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  hidePassword = true;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;
  isDragging: boolean;
  isTeknik: boolean;
  brandingLogo: string;
  imagesData: Image[] = [];
  loadSlider = false;
  isISS: boolean;
  public APP_LOGO = '';
  public APP_NAME = '';
  isAkMerkez: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private dialogController: MatDialog,
    public translateService: TranslateService,
    private brandingService: BrandingService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.createForm();

    this.isTeknik = environment.isTeknik;
    this.brandingService.init().subscribe(x => {
      this.APP_LOGO = environment.brandLogo;
      this.APP_NAME = environment.appName;
      this.imagesData = environment.sliderImageData;
      this.loadSlider = true;
    });
  }
  customOptions: any = {
    loop: true,
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    dots: false,
    center: true,
    URLhashListener: true,
    startPosition: 'URLHash',
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  ngOnInit(): void {
    this.isAkMerkez = location.origin.indexOf('talep.akmerkez.com.tr') > -1;
    this.isISS =
      location.origin === 'https://tesisyonetimi.issturkiye.com' ? true : false;
  }

  ngOnDestroy(): void {
    this.isLoading = false;
  }

  login(): void {

    this.error = null;
    this.isLoading = true;
    const param = this.loginForm.value;
    param.appID = environment.appID;
    param.domain = environment.domain;
    const login$ = this.authenticationService.login(param);
    login$
      .pipe(
        mergeMap((credentials) => {
          // HACK
          if (credentials.kvkkOnay || credentials.teknikUser) {
            return of(credentials);
          }
          return this.kvkkOnayModal().pipe(
            map((onay) => {
              if (onay) {
                return credentials;
              }
            })
          );
          // return of(credentials);
        }),
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (credentials) => {

          if (credentials === undefined) {
            this.authenticationService.logout();
            return;
          }
          if (this.loginForm.get('remember').value) {
            localStorage.setItem('rememberUser', param.username);
          }
          // log.debug(`${credentials.kullaniciAdi} successfully logged in`);
          if (!credentials.teknikUser) {
            this.router.navigate(
              [this.route.snapshot.queryParams.redirect || ''],
              { replaceUrl: true }
            );
          } else {
            this.router.navigate(['/teknik-talepler'], { replaceUrl: true });
          }
          this.cdr.detectChanges();
        },
        (error) => {
          this.isLoading = false;
          log.debug(`Login error: ${error}`);
          this.error = error;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  kvkkOnayModal(): Observable<boolean> {
    const modal = this.dialogController.open(KvkkOnayComponent, {
      height: '400px',
      width: '700px',
    });
    return modal.afterClosed();
  }

  private createForm(): void {
    const lastUser = localStorage.getItem('rememberUser');
    this.loginForm = this.formBuilder.group({
      username: [lastUser, Validators.required],
      password: ['', Validators.required],
      remember: false,
    });
  }
}
