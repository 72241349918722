import { Injectable } from '@angular/core';
import { ApiUrls } from 'src/app/api-urls.enum';
import { DataService } from '../../core/communication/rest-service-invoker.service';
import { Bloklar } from './models/bloklar.model';
import { Bolumler } from './models/bolumler.model';
import { MulkiyetDurum } from './models/mulkiyet-durum.model';
import { TesisBilgileri } from './models/tesis-bilgileri.model';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private dataService: DataService) {}

  getRegisterPageData(tesisID: number) {
    return this.dataService.get<{
      mulkiyetDurumlari: MulkiyetDurum[];
      bloklar: Bloklar[];
      tesisBilgileri: TesisBilgileri;
    }>(ApiUrls.GetRegisterPage, { tesisID });
  }

  getRegisterPageBolumler(tesisID: number, blokID: number){
    const param = {tesisID,blokID};
    return this.dataService.get<Bolumler[]>(ApiUrls.GetRegisterPageBolumler,param);
  }

  createRegisterPage(data: any) {
    return this.dataService.post<any>(ApiUrls.CreateRegisterPage, data);
  }

  sifremiUnuttum(data: any) {
    return this.dataService.post(ApiUrls.SifremiUnuttum, data);
  }

  sifreYenile(data: any) {
    return this.dataService.post(ApiUrls.SifreYenile, data);
  }

}
