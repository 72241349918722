import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../material.module';
import { LanguageSelectorComponent } from './language-selector.component';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
  imports: [CommonModule, TranslateModule,  MaterialModule,InlineSVGModule.forRoot(),],
  declarations: [LanguageSelectorComponent],
  exports: [LanguageSelectorComponent],
})
export class I18nModule {}
