import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeTr from '@angular/common/locales/tr';
import localeRU from '@angular/common/locales/ru';
import localeUz from '@angular/common/locales/uz';
import localeAr from '@angular/common/locales/ar';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { MatTableExporterModule } from 'mat-table-exporter';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule, ErrorHandlerInterceptor } from './core';
import { LoadingInterceptor } from './core/http/loading.interceptor';
import { LayoutModule } from './layout/layout.module';
import { MaterialModule } from './material.module';
import { LoginModule } from './senyonet-musteri/login/login.module';
import { SharedModule } from './shared';

registerLocaleData(localeTr, 'tr-TR');
registerLocaleData(localeRU, 'ru-Ru');
registerLocaleData(localeUz, 'uz-UZ');
registerLocaleData(localeAr, 'ar-AR');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    TranslateModule.forRoot(),
    MaterialModule,
    SharedModule,
    LoginModule,
    TelerikReportingModule,
    NgbModule,
    MatTableExporterModule,
    AppRoutingModule],
  providers: [
    { provide: LOCALE_ID, useValue: 'tr-TR' },

    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
