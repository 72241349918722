<button *ngIf="icon; else text" class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" [matMenuTriggerFor]="languageMenu">
  <span class="svg-icon svg-icon-dark svg-icon-1" [inlineSVG]="'assets/icons/Home/Globe.svg'" [cacheSVG]="true">
  </span>
</button>
<ng-template #text>
    <button class="btn btn-white btn-active-primary text-uppercase ms-3" [matMenuTriggerFor]="languageMenu">
    {{ currentLanguage }}
    <span class="svg-icon svg-icon-1 mx-0" [inlineSVG]="'assets/icons/Navigation/Angle-down.svg'" [cacheSVG]="true">
    </span>
  </button>
</ng-template>
<mat-menu #languageMenu="matMenu">
    <button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language)">
    {{ language }}
  </button>
</mat-menu>