import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CredentialsService } from 'src/app/core/credentials.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss'],
})
export class ReportViewerComponent implements OnInit {
  aciklama: string;
  viewerContainerStyle: any;

  public serviceUrl: string;
  public reportDetails: any[];
  public token: string;
  public reportName: string;
  public reportCount: number;
  public parameters: any;

  constructor(
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<ReportViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userInfo: CredentialsService
  ) {
    // this.aciklama = data;

    this.viewerContainerStyle = {
      position: 'relative',
      width: '1000px',
      height: '800px',
      left: '5px',
      right: '5px',
      ['font-family']: 'ms sans serif',
    };
  }

  ngOnInit() {

    this.serviceUrl = environment.serverUrl + 'v1/reports';
    this.reportName = this.data.reportName; //'samet.trdx';
    this.parameters = this.data.reportParameter;
    this.token = this.userInfo.credentials.signedKey;
  }

  closeDialog(durum: boolean): void {
    this.dialogRef.close(durum);
  }
  removeItems() {
    document
      .querySelectorAll(
        "#trv-main-menu-export-command > div > ul > li:not(.k-first) "
      )
      .forEach((x) => {
        x.remove();
      });
  }
}
