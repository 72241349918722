import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { PasswordRefreshComponent } from './password-refresh/password-refresh.component';
import { PasswordComponent } from './password/password.component';
import { RegisterComponent } from './register/register.component';
import { RegisterResolver } from './register/register.resolver';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'Login.Title' }, },
  {
    path: 'password',
    component: PasswordComponent,
    data: {
      title: 'Title.ChangePassword'
    },
  },
  {
    path: 'password-refresh',
    component: PasswordRefreshComponent,
    data: {
      title: 'Title.ChangePassword'
    },
  },
  {
    path: 'register/:tesisID',
    component: RegisterComponent,
    resolve: { register: RegisterResolver },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule { }
