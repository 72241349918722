import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Layout } from './layout/layout';
import { ExternalDuyuruComponent } from './senyonet-musteri/external-old/external-duyuru/external-duyuru.component';
import { ExternalHtmlReportsResolver } from './senyonet-musteri/external-old/external-html-reports/external-html-reports.resolver';
import { ExternalHtmlReportsComponent } from './senyonet-musteri/external-yeni/external-html-reports/external-html-reports.component';
import { OdemeSonucComponent } from './senyonet-musteri/external-yeni/odeme-sonuc/odeme-sonuc.component';
import { ExternalRedirecterComponent } from './shared/components/external-redirecter/external-redirecter.component';
import { ZiyaretciGirisiComponent } from './senyonet-musteri/external-yeni/ziyaretci-girisi/ziyaretci-girisi.component';
import { AuthenticationGuard } from '@core/auth/authentication.guard';
import { LayoutResolver } from './layout/layout.resolver';

export const routes: Routes = [
  {
    path: 'external-yeni',
    loadChildren: () =>
      import('./senyonet-musteri/external-yeni/external-yeni.module').then(
        (m) => m.ExternalYeniModule
      ),
  },
  {
    path: 'p',
    loadChildren: () =>
      import('./senyonet-musteri/external-yeni/external-yeni.module').then(
        (m) => m.ExternalYeniModule
      ),
  },
  {
    path: 'external/external-duyuru/:guidLink',
    component: ExternalDuyuruComponent,
    data: {
      title: "Title.ExternalDuyuru"
    }
  },
  {
    path: 'external/ziyaretci-girisi/:guidLink',
    component: ZiyaretciGirisiComponent,
    data: {
      title: "Title.VisitorEnter"
    }
  },
  {
    path: 'external/external-payment/:guidLink',
    component: ExternalRedirecterComponent,
    data: {
      title: "Title.ExternalPayment"
    }
  },
  /**/
  {
    path: 'external/odeme-sonuc/GetOnlineOdemeSonucFail/:aciklama',
    component: OdemeSonucComponent,
    data: { durum: false },
  },
  {
    path: 'external/odeme-sonuc/GetOnlineOdemeSonucOK/:aciklama',
    component: OdemeSonucComponent,
    data: { durum: true },
  },
  {
    path: 'external/external-html-reports/:guidLink',
    component: ExternalHtmlReportsComponent,
    data: {
      title: 'Title.ExternalHtmlReports'
    },
    resolve: { htmlReport: ExternalHtmlReportsResolver },
  },
  /**/
  {
    path: 'externals',
    loadChildren: () =>
      import('./senyonet-musteri/external-old/external-old.module').then(
        (m) => m.ExternalOldModule
      ),
  },
  { path: 'p/:guidLink', redirectTo: 'external/external-payment/:guidLink', pathMatch: 'full' },
  {
    path: '',
    canActivate: [AuthenticationGuard],
    data: { reuse: true },
    resolve: { menu: LayoutResolver },
    loadChildren: () =>
      import('./layout/layout.module').then((m) => m.LayoutModule),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      // enableTracing: false,
      // preloadingStrategy: PreloadAllModules,
      // preloadingStrategy: NoPreloading
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
