<div class="d-flex flex-column flex-lg-row flex-column-fluid">
  <div
    class="d-flex flex-column flex-lg-row-auto w-lg-400px w-xl-600px positon-xl-relative bg-primary text-white"
  >
    <div *ngIf="!isAkMerkez"
      class="d-flex flex-column position-xl-fixed top-0 bottom-0 w-lg-400px w-xl-600px"
    >
      <div class="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
        <a href="#" class="py-9 mb-5">
          <img alt="Logo" [src]="APP_LOGO" class="h-60px" />
        </a>
        <h1 class="fw-bolder fs-2qx pb-5 pb-md-10">{{APP_NAME}}</h1>
      </div>
      <div class="d-flex flex-row-auto min-h-100px owl-wrapper">
        <owl-carousel-o [options]="customOptions" #owlCat>
          <ng-container *ngFor="let image of imagesData">
            <ng-template carouselSlide>
              <div class="d-flex flex-row-fluid flex-column text-center">
                <p class="fw-bold fs-2 p-10 pt-lg-20">
                  {{ image.title | translate }}
                </p>
                <img
                  class="d-none d-lg-block"
                  [src]="image.src"
                  [alt]="image.alt"
                  title="{{ image.title | translate }}"
                />
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
    <img style="height: 100vh;" *ngIf="isAkMerkez" [src]="imagesData[0].src" [alt]="imagesData[0].alt" title="Ak Merkez">
  </div>
  <div class="d-flex flex-column flex-lg-row-fluid py-10">
    <div class="d-flex flex-center flex-column flex-column-fluid">
      <div class="w-lg-500px p-10 p-lg-15 mx-auto">
        <form
          (ngSubmit)="dogrulamaKoduGonder()"
          [formGroup]="passwordForm"
          novalidate
          autocomplete="off"
        >
          <div class="text-start mb-10">
            <h1 class="text-dark fw-bolder mb-3">
              {{ "Login.Welcome" | translate }}
            </h1>
            <div class="text-gray-500 fw-semibold fs-6">
              {{ "Login.PasswordDescription" | translate }}
            </div>
          </div>

          <div class="mb-10">
            <mat-radio-group
              class="d-flex gap-10"
              aria-label="Select an option"
              value="1"
            >
              <mat-radio-button value="1" (click)="inputType = 1"
                >{{ "Login.EmailileDogrula" | translate }}
              </mat-radio-button>
              <mat-radio-button value="2" (click)="inputType = 2"
                >{{ "Login.GSMileDogrula" | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div
            class="alert alert-danger mb-3 mt-10"
            role="alert"
            [hidden]="!error || isLoading"
          >
            {{ "Login.FormError" | translate }}
          </div>
          <div class="mb-8" [ngSwitch]="inputType">
            <mat-form-field
              floatLabel="always"
              [hideRequiredMarker]="true"
              *ngSwitchCase="1"
            >
              <input
                type="email"
                matInput
                class="form-control form-control-solid"
                autocomplete="new-password"
                formControlName="email"
                [placeholder]="'Login.Email' | translate"
                required
                [ngClass]="{
                  'is-invalid':
                    passwordForm.controls['email'].invalid &&
                    passwordForm.controls['email'].touched,
                  'is-valid': passwordForm.controls['email'].valid
                }"
              />
              <mat-error
                *ngIf="
                  passwordForm.controls.email.invalid &&
                  passwordForm.controls.email.touched &&
                  inputType == 1
                "
              >
                <span> {{ "Login.Email" | translate }} </span>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              floatLabel="always"
              [hideRequiredMarker]="true"
              *ngSwitchCase="2"
            >
              <input
                (keyup)="gsmOnKeyupEvent($event)"
                [angularFormsMask]="['(DDD) DDD DD DD']"
                [validateMaskInput]="true"
                autocomplete="new-password"
                matInput
                class="form-control form-control-solid"
                formControlName="gsm"
                [placeholder]="'Login.TelNo' | translate"
                required
              />
              <mat-error
                *ngIf="
                  passwordForm.controls.gsm.invalid &&
                  passwordForm.controls.gsm.touched &&
                  inputType == 2
                "
              >
                <span> {{ "Login.TelNo" | translate }} </span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="d-flex flex-wrap justify-content-center pb-lg-0">
            <button
              class="btn btn-lg btn-primary w-100 mb-5"
              type="submit"
              [disabled]="
                (passwordForm.controls.email.invalid && inputType == 1) ||
                (passwordForm.controls.gsm.invalid && inputType == 2) ||
                isLoading
              "
              [attr.data-indicator]="isLoading ? 'on' : null"
            >
              <span class="indicator-label">
                {{ "Login.DogrulamaKoduGonder" | translate }}
              </span>
              <span class="indicator-progress"
                >{{ "Global.LutfenBekleyin" | translate }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>

          <div class="text-center text-muted fw-bolder my-10">
            {{ "Login.Yada" | translate }}
          </div>

          <div class="text-center mb-10">
            <div class="text-gray-400 fw-bold fs-4">
              <a class="btn-link fw-bolder" routerLink="/login">{{
                "Login.GirisSayfasi" | translate
              }}</a>
            </div>
          </div>
        </form>
      </div>
      <div class="d-flex flex-center fw-bold fs-6"></div>
    </div>
    <div class="d-flex flex-center flex-wrap px-5">
      <div class="d-flex fw-semibold text-primary fs-base">
        <app-language-selector></app-language-selector>
      </div>
    </div>
  </div>
</div>

<!--



<div class="login-wrapper d-flex flex-column flex-grow-1 flex-shrink-1 h-100">
  <div class="login d-flex flex-column flex-lg-row flex-grow-1 flex-shrink-0">
    <mat-card class="login-aside d-flex flex-column flex-row-auto p-5 overflow-auto">
      <div fxLayoutAlign="space-between stretch" class="mb-5">
        <img class="w-sm-60px w-md-100px" id="logo" [src]="brandLogo" />
      </div>
      <div class="content">
        <div class="alert alert-danger mb-3" role="alert" [hidden]="!error || isLoading">
          {{ "Login.FormError" | translate }}
        </div>
        <form (ngSubmit)="dogrulamaKoduGonder()" [formGroup]="passwordForm" novalidate>
          <div class="pt-0 py-lg-4 pb-3">
            <mat-card-title class="font-size-h4 font-size-h1-lg">
              {{ "Login.Welcome" | translate }}
            </mat-card-title>
            <mat-card-subtitle class="font-size-h4">
              {{ "Login.PasswordDescription" | translate }}
            </mat-card-subtitle>
          </div>
          <div>
                        <button mat-raised-button [color]="inputType==0?'primary':'secondary'" (click)="inputType=0">
                            <span> {{ "Login.EmailileDogrula" | translate }} </span>
                        </button>
                        <button mat-raised-button [color]="inputType==1?'primary':'secondary'"  (click)="inputType=1">
                            <span> {{ "Login.GSMileDogrula" | translate }} </span>
                        </button>
                    </div>
          <div>
            <mat-radio-group aria-label="Select an option" value="1">
              <mat-radio-button value="1" (click)="inputType=1">{{ "Login.EmailileDogrula" | translate }}
              </mat-radio-button>
              <mat-radio-button value="2" (click)="inputType=2">{{ "Login.GSMileDogrula" | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="alert alert-danger mb-3 mt-10" role="alert" [hidden]="!error || isLoading">
            {{ "Login.FormError" | translate }}
          </div>
          <div fxLayout="column" [ngSwitch]="inputType">
            <mat-form-field appearance="outline" [hideRequiredMarker]="true" *ngSwitchCase="1">
              <input type="email" matInput formControlName="email" [placeholder]="'Login.Email' | translate" required />
              <mat-error
                *ngIf="passwordForm.controls.email.invalid && passwordForm.controls.email.touched && inputType==1">
                <span> {{ "Login.Email" | translate }} </span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" [hideRequiredMarker]="true" *ngSwitchCase="2">
              <input (keyup)="gsmOnKeyupEvent($event)" [angularFormsMask]="['(DDD) DDD DD DD']"
                [validateMaskInput]="true" matInput formControlName="gsm" [placeholder]="'Login.TelNo' | translate"
                required />
              <mat-error *ngIf="passwordForm.controls.gsm.invalid && passwordForm.controls.gsm.touched && inputType==2">
                <span> {{ "Login.TelNo" | translate }} </span>
              </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" type="submit"
              [disabled]="(passwordForm.controls.email.invalid && inputType==1) || (passwordForm.controls.gsm.invalid && inputType==2) || isLoading">
              <app-loader class="d-inline-block" [isLoading]="isLoading"></app-loader>
              <span> {{ "Login.DogrulamaKoduGonder" | translate }} </span>
            </button>
          </div>
          <div class="alert pl-0 mb-0 mt-4" role="alert" fxLayout="column" fxLayout.gt-xs="row">
            <div>
              <a class="alert-link mr-2" routerLink="/login" mat-button color="primary">
                <span> {{ "Login.GirisSayfasi" | translate }} </span>
              </a>
            </div>
          </div>
        </form>
      </div>
    </mat-card>
  </div>
</div> -->
