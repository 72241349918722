import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeDosyaDetay } from '../../models';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent implements OnInit {
  imageName:string;
  image: string;
  dialogData: any;

  constructor(
    public dialogRef: MatDialogRef<ImagePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) private data: KeDosyaDetay
  ) {
  }
  ngOnInit(): void {
    const dosya = this.data;
    this.imageName = dosya.dosyaAdi;
    this.image = 'data:' + dosya.contentType + ';base64,' + dosya.dosya;

  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
