import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private snackBarConfig: MatSnackBarConfig;
  private snackBarRef: MatSnackBarRef<any>;
  private snackBarAutoHide = '3000';
  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  async open(message = '') {
    this.translateService
      .get([message])
      .subscribe((contanier: { [key: string]: string }) => {
        message = contanier[message] || message;
      });

    this.snackBarConfig = new MatSnackBarConfig();
    this.snackBarConfig.verticalPosition = 'top';
    this.snackBarConfig.horizontalPosition = 'right';
    this.snackBarConfig.panelClass = ['mat-snack-bar'];
    this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);
    this.snackBar.open(message, '', this.snackBarConfig);
  }
}
