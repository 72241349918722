import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cssVars from 'css-vars-ponyfill';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  constructor(private http: HttpClient) { }
  init(): Observable<any> {
    const domain = window.location.protocol + '//' + window.location.host + '/';
    return this.http.get(domain + 'assets/branding/branding.json').pipe(
      map((x: any) => {

        let options: any;
        const currentData = x.find(
          (y: any) => y.Domain === window.location.host
        );
        if (currentData !== undefined) {
          options = {
            watch: true,
            preserveStatic: false,
            variables: currentData.Colors,
          };
          environment.brandLogo = currentData.Logo;
          environment.appName = currentData.AppName;
          environment.sliderImageData = currentData.Sliders;
          environment.appID = currentData.AppID;
          environment.domain = currentData.UsesDomain;
          environment.insideLogo = currentData.InsideLogo;
          environment.favIcon = currentData.FavIcon;
          environment.webType = currentData.WebType;
          window.document.title = `${window.document.title.replace("| " + environment.appName, "")} | ${environment.appName}`;
          try {
            document.getElementById('favicon').setAttribute('href', './' + environment.favIcon);
          } catch {

          }
          cssVars(options);
          return currentData;
        }
      })
    );
  }
}
