import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LoginService } from '../login.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterResolver  {
  constructor(private loginService: LoginService) {}
  resolve(route: ActivatedRouteSnapshot){

    const tesisID = Number(route.paramMap.get('tesisID'));
    return this.loginService.getRegisterPageData(tesisID);

  }
}
