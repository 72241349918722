import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CredentialsService } from '../../../core/credentials.service';
import { ZiyaretcilerService } from '../../ziyaretciler/services/ziyaretciler.service';
import { timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-ziyaretci-girisi',
  templateUrl: './ziyaretci-girisi.component.html',
  styleUrls: ['./ziyaretci-girisi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZiyaretciGirisiComponent implements OnInit, AfterViewInit {
  @ViewChild("progressBar") progressBar: ElementRef;

  qrRequestGuid: any;
  qrCode: any;

  initialTime: number = 15
  timeLeft = this.initialTime

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private translateService: TranslateService,
    private service: ZiyaretcilerService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {
    this.qrRequestGuid = this.route.snapshot.paramMap.get('guidLink');
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    // this.timer();
    this.getQrRequestDetails()
  }

  timer() {
    const timer = setInterval(() => {
      this.timeLeft--;
      this.cdr.detectChanges()
      if (this.timeLeft == 0) {
        clearInterval(timer);
      }
    }, 1000);
  }

  refreshBarcode(){
    this.timeLeft = this.initialTime
    this.timer()
  }

  getQrRequestDetails() {
    this.service
      .getQrRequestResult(this.qrRequestGuid)
      .subscribe((requestResult) => {
        if (requestResult.hasError || !requestResult.response) {
          // alert(this.translateService.instant('ZiyaretciGirisi.InvalidQrCodeRequest'));
          console.log(requestResult.errors);
        }
        else {
          this.qrCode = requestResult.response;
        }

        this.cdr.detectChanges();
      });
  }
}