import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { KeDosyalar } from '../../models/index';
import { SharedService } from '../../shared.service';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent implements OnInit {
  @Input() dosyalar: KeDosyalar[];
  constructor(private sharedService: SharedService, public dialog: MatDialog, private snackBarService: SnackBarService) { }

  ngOnInit() { }

  downloadFile(dosya: KeDosyalar): void {

    if (dosya.contentType.includes('image') || dosya.dosyaAdi.match(/\.(jpeg|jpg|gif|png)$/) != null) {
      const dialogRef = this.dialog.open(ImagePreviewComponent, {
        width: '100%',
        maxWidth: '80%',
        // panelClass: 'padding-0',
        data: dosya,
      });
    } else {
      this.snackBarService.open('Global.DownloadStarted');
      this.sharedService.downloadFileByService(dosya.id, dosya.dosyaID);
    }
  }
}
