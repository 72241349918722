<div class="mb-3" fxLayoutAlign="space-between center">
  <h3 class="m-0" mat-dialog-title>
    {{ "OnlinePayment.ResultTitle" | translate }}
  </h3>
</div>
<mat-dialog-content align="center">
  <div class="d-flex flex-row align-items-center">
    <span
        class="spinner-border spinner-border-lg align-middle ms-2"
      ></span>
<div class="d-flex flex-column ms-5 text-start">
  {{ "OnlinePayment.BankResult" | translate }} : {{ aciklama }}<br />
  {{ "Global.YonlendiriliyorsunuzLutfenBekleyiniz" | translate }}
  <button (click)="sendMessage()">gönder</button>
</div>
    <!-- <button
    class="btn btn-lg btn-primary w-100 mb-5"
    (click)="odemeBitir()"
    [disabled]="isLoading"
    [attr.data-indicator]="isLoading ? 'on' : null"
  >
    <span class="indicator-label">
      {{ "Global.Continue" | translate }}
    </span>
    <span class="indicator-progress"
      >{{ "Global.LutfenBekleyin" | translate }}
      <span
        class="spinner-border spinner-border-sm align-middle ms-2"
      ></span>
    </span>
  </button> -->
  </div>

  <!-- {{ "OnlinePayment.BankResult" | translate }} : {{ aciklama }}<br />
  {{ "Global.YonlendiriliyorsunuzLutfenBekleyiniz" | translate }} -->
</mat-dialog-content>
<mat-dialog-actions align="end"> </mat-dialog-actions>
