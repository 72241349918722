enum Tablolar {
  TY_Talepler = 'TY_Talepler',
  WEB_Duyurular = 'WEB_Duyurular',
  SM_Etkinlikler = 'SM_Etkinlikler',
  DY_Dokumanlar = 'DY_Dokumanlar',
  SM_Kararlar = 'SM_Kararlar',
  FN_HesapHareketleri = 'FN_HesapHareketleri',
}

enum Mulkiyet {
  Kullanan = 1,
  Benim = 2,
  Kiracim = 3,
}

enum SanalPosBankalarEnum {
  Akbank = 1,
  GarantiBankasi = 2,
  IsBankasi = 3,
  Vakıfbank = 4,
  Denizbank = 5,
  PayTR = 19,
  NKolay = 20,
  Iyzico = 15,
  Sipay = 23,
  YapiKredi = 21
}

enum MobileDeviceTypes {
  Android = 1,
  Ios = 2,
}

enum BildirimTurleri {
  Anket = 0,
  Duyuru = 1,
  Etkinlik = 2,
  KisiselBildirim = 3,
}

enum MenulerEnum {
  OzlukBilgilerim = 154,
  IkametEdenUyelikler = 156,
  BolumBilgilerim = 155,
  AracListesi = 172,
  Bilgilerim = 176,
  HesapHareketleri = 157,
  OnlineOdeme = 171,
  Anketler = 158,
  Dokumanlar = 159,
  Taleplerim = 160,
  Kararlar = 216,
  MekanRezervasyonlari = 161,
  BankaBilgileri = 177,
  GelirGider = 221,
  NakitAkis = 222,
  SiteOzetBilgileri = 223,
  TelefonRehberi = 163,
  SifreDegistir = 210,
  Ziyaretciler = 226,
  YeniTalep = 160,
  KVKK = 231
}

enum HareketTuruEnum {
  NakitTahsilat = 10,
  NakitOdeme = 15,
  BankayaYatirilan = 20,
  BankadanCekilen = 25,
  GelenHavale = 30,
  GonderilenHavale = 35,
  Virman = 40,
  Masraf = 41,
  Devir = 42,
  MutabakatBorc = 43,
  MutabakatAlacak = 44,
  Islem = 45,
  KrediKartiIslemi = 46,
  Satis = 47,
  Gelir = 48,
  KurFarki = 49,
  CekGiris = 50,
  CekCikisCariHesaba = 60,
  CekCikisBankayaTahsil = 61,
  CekCikisBankayaTeminat = 62,
  KendiCekimizCikisCariHesaba = 63,
  BankadaTahsil = 70,
  PortfoydeTahsil = 71,
  MusterideTahsil = 72,
  BankadanPortfoyeIade = 80,
  CaridenPortFoyeIade = 81,
  PortfoydenCariyeIade = 82,
  CaridenKendiCekimizIade = 83,
  SatisFaturasi = 90,
  SatisIadeFaturasi = 91,
  AlisFaturasi = 95,
  AlisIadeFaturasi = 96,
  GiderPusulasi = 22,
  SerbestMeslekMakbuzu = 21,
  CaridenPortfoyeKarsiliksizIade = 84,
  BankadanPortfoyeKarsiliksizIade = 85,
  PortfoydenCariyeKarsiliksizIade = 86,
  OzelHesapHareketi = 55,
}

enum OdemeTuruEnum {
  TekCekim = 1,
  Taksitli = 2,
}
enum TesisParametreleriEnums {
  RezervasyonForm = 204,
  MobilUygulamaModu = 314
}

export {
  BildirimTurleri, HareketTuruEnum, MenulerEnum, MobileDeviceTypes, Mulkiyet, OdemeTuruEnum, SanalPosBankalarEnum, Tablolar, TesisParametreleriEnums
};

