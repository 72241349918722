import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISozlesme } from 'src/app/senyonet-musteri/gizlilik-politikasi/models/gizlilik-politikasi.model';

@Component({
  selector: 'app-virtual-pos-terms',
  templateUrl: './virtual-pos-terms.component.html',
  styleUrls: ['./virtual-pos-terms.component.scss']
})
export class VirtualPosTermsComponent implements OnInit {
  sozlesme: ISozlesme;
  constructor(
    public dialogRef: MatDialogRef<VirtualPosTermsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISozlesme
  ) {
    this.sozlesme = data;
  }

  ngOnInit() {
  }

  accept() {
    this.dialogRef.close(true);
  }

  dismiss() {
    this.dialogRef.close(false);
  }
}
