import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TesisParametreleriEnums } from '../../enums/enums';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-external-redirecter',
  templateUrl: './external-redirecter.component.html',
  styleUrls: ['./external-redirecter.component.scss']
})
export class ExternalRedirecterComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private actr: ActivatedRoute,
  ) { }

  ngOnInit() {
    //debugger;
    const guidLink = this.actr.snapshot.paramMap.get('guidLink');
    const param = {
      parametreID: TesisParametreleriEnums.MobilUygulamaModu,
      guid: guidLink
    };
    this.sharedService.getOdemeTuru(param).subscribe(x => {
      if (x.result) {
        if (x.deger === "0") {
          this.router.navigateByUrl('/externals/external-payment/' + guidLink);
        } else if (x.deger == "1") {
          this.router.navigateByUrl('/external-yeni/external-payment/' + guidLink);
        }
      }
    })
  }

}
