<mat-dialog-content>
  <div [innerHTML]="message" style="white-space: pre-wrap;"></div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    mat-raised-button
    color="primary"
    (click)="onConfirmClick()"
    tabindex="1"
  >
    {{ confirmButtonText | translate}}
  </button>
  <button mat-raised-button mat-dialog-close tabindex="-1">
    {{ cancelButtonText | translate}}
  </button>
</mat-dialog-actions>
