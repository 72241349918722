import { Injectable } from '@angular/core';
import { ApiUrls } from 'src/app/api-urls.enum';
import { CredentialsService } from 'src/app/core/credentials.service';
import { DataService } from '../../../core/communication/rest-service-invoker.service';
import { SaveZiyaretciModel } from '../models';
import { Ziyaretci } from '../models/ziyaretci';
@Injectable({
  providedIn: 'root',
})
export class ZiyaretcilerService {
  constructor(private dataService: DataService, private credentialService: CredentialsService) { }
  getZiyaretciler(
    baslangicTarihi: string,
    bitisTarihi: string,
    blokId: number,
    bolumID: number,
    appID: string
  ) {
    const param = this.credentialService.getAllCredential();
    param.baslangicTarihi = baslangicTarihi;
    param.bitisTarihi = bitisTarihi;
    param.appID = appID;
    return this.dataService.get<Ziyaretci[]>(
      ApiUrls.GetZiyaretciler,
      param
    );
  }
  saveZiyaretci(param: any) {
    return this.dataService.post<any>(
      ApiUrls.SaveZiyaretci,
      param
    );
  }

  deleteZiyaretci(data: SaveZiyaretciModel) {
    const param = { ...data, ...this.credentialService.getAllCredential() };
    return this.dataService.post<any>(
      ApiUrls.DeleteZiyaretci,
      param
    );
  }

  getQrRequestResult(qrRequestGuid: any) {
    return this.dataService.get<any>(
      ApiUrls.GetZiyaretciGirisQrCode,
      { qrRequestGuid: qrRequestGuid }
    );
  }
}
