import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { IStatusModel } from './models/IStatusModel';

@Component({
  selector: 'app-external-html-reports',
  templateUrl: './external-html-reports.component.html',
  styleUrls: ['./external-html-reports.component.scss'],
})
export class ExternalHtmlReportsComponent implements OnInit {
  public report: IStatusModel;
  constructor(private actr: ActivatedRoute,
    protected sanitizer: DomSanitizer) {
  }

  ngOnInit() {

    const resolverData = this.actr.snapshot.data.htmlReport;
    resolverData.resultMessage = this.sanitizer.bypassSecurityTrustHtml(resolverData.resultMessage);
    this.report = resolverData;
  }
}
