import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  RendererFactory2
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  PRIMARY_OUTLET,
  Router
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { untilDestroyed } from 'src/app/core/custom-rxjs-operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './core/auth/authentication.service';
import { I18nService } from './core/i18n';
import { BrandingService } from './core/services/branding.service';
import { LoadingService } from './core/services/loading.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'senyonet-musteri-web';
  isLoading = false;
  themingSubscription: Subscription;
  private renderer: Renderer2;
  constructor(
    private i18nService: I18nService,
    private router: Router,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private authenticationService: AuthenticationService,
    rendererFactory: RendererFactory2,
    private brandingService: BrandingService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  ngAfterViewInit(): void { }
  ngOnDestroy(): void { }
  ngOnInit(): void {
    this.initTranslate();
    this.brandingService.init().subscribe();
    this.renderer.addClass(document.body, 'light-theme');
    this.loadingService.isLoading$.subscribe((loading) => {
      this.isLoading = loading;
      document.body.setAttribute('data-st-page-loading', loading ? 'true' : 'false');
      this.cdr.detectChanges();
    });
  }
  initTranslate(): void {
    // Setup translations
    const currentLang =
      window.navigator.language.substr(0, 2).toString() +
      '-' +
      window.navigator.language.substr(0, 2).toString().toUpperCase();
    this.i18nService.init(currentLang, environment.supportedLanguages);

    // Title translate
    const onNavigationEnd = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === PRIMARY_OUTLET),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title) + ' | ' + environment.appName);
        }
      });
  }
}
