import {
  AfterViewInit,
  ChangeDetectionStrategy, Component, ElementRef, Input
} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements AfterViewInit {
  @Input() isLoading = false;
  @Input() size = 1;
  @Input() color: any;
  @Input() message: string | undefined;

  // _isloading = false;

  constructor(
    private elem: ElementRef
  ) { }

  ngAfterViewInit() {
    if (!!this.color) {
      const element = this.elem.nativeElement;
      const circle = element.querySelector("circle");
      circle.style.stroke = this.color;
    }
  }
}
