import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FacilityService {

  private _tesisDegistir: BehaviorSubject<boolean> =
  new BehaviorSubject<boolean>(false);
  public get tesisDegistir$(): Observable<boolean> {
    return this._tesisDegistir.asObservable();
  }



  public readonly Tesisler: any[];
  constructor() {
    this._tesisID = '';
    this._bolumID = '';
    this._firmaID = '';
    this._blokID = '';
    this._firmaKisiID = '';
    this._adiSoyadi = '';
    this.Tesisler = [];
  }

  tesisDegistir(){

    this._tesisDegistir.next(true);
  }

  // tslint:disable-next-line: variable-name
  private _tesisID: string;
  public get tesisID(): string {
    return this._tesisID;
  }
  public set tesisID(v: string) {
    this._tesisID = v;
  }

  // tslint:disable-next-line: variable-name
  private _firmaKisiID: string;
  public get firmaKisiID(): string {
    return this._firmaKisiID;
  }
  public set firmaKisiID(v: string) {
    this._firmaKisiID = v;
  }

  // tslint:disable-next-line: variable-name
  private _adiSoyadi: string;
  public get adiSoyadi(): string {
    return this._adiSoyadi;
  }
  public set adiSoyadi(v: string) {
    this._adiSoyadi = v;
  }

  // tslint:disable-next-line: variable-name
  private _bolumID: string;
  public get bolumID(): string {
    return this._bolumID;
  }
  public set bolumID(v: string) {
    this._bolumID = v;
  }
  // tslint:disable-next-line: variable-name
  private _blokID: string;
  public get blokID(): string {
    return this._blokID;
  }
  public set blokID(v: string) {
    this._blokID = v;
  }
  // tslint:disable-next-line: variable-name
  private _firmaID: string;
  public get firmaID(): string {
    return this._firmaID;
  }
  public set firmaID(v: string) {
    this._firmaID = v;
  }
}
