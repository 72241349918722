import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CredentialsService } from '../credentials.service';
import { FacilityService } from '../facility.service';
import { SenyonetUtils } from '../utilities/utils';
import { WINDOW } from '../utilities/window.provider';
import { AuthenticationService } from './authentication.service';
const config = {
  Authorization: 'Authorization',
  isSigned: 'is-signed',
  HeaderTenantKey: 'tenantKey',
  HeaderUserIDKey: 'user-id',
  HeaderDilKoduKey: 'user-lang',
  HeaderDilKoduID: 'user-lang-id',
  HeaderAdiSoyadi: 'firma-adiSoyadi',
  HeaderMobileAppKey: 'mobile-app',
  HeaderTesisId: 'tesis-id',
  HeaderBolumId: 'bolum-id',
  HeaderBlokId: 'blok-id',
  HeaderFirmaId: 'firma-id',
  HeaderFirmaKisiId: 'firma-kisi-id',
  HeaderHesapID: 'hesap-id',
  HeaderRefererUrl: 'referer-url',
  HeaderAppID: 'app-id',
};

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  private tenantName: string;
  private authService: AuthenticationService;
  private credentialsService: CredentialsService;
  private url = '';
  constructor(
    @Inject(WINDOW) private window: Window,
    private injector: Injector,
    private facilityService: FacilityService
  ) {

    this.url = this.window.location.hostname;
    this.tenantName =
      this.url.indexOf('.') >= 0 ? this.url.split('.')[0] : 'senkron';
    /*
      Angular Team resolved this issue in Angular 5.2.3 released 31 January 2018.
      After updating angular version you will be able to inject services that use HTTPClient as normal in constructor

    Bug Fixes

    common: allow HttpInterceptors to inject HttpClient (#19809) (ed2b717), closes #18224
    Artık injector'a gerek yok, ihtiyaç durumunda düzeltilebilir.
    */

    this.authService = this.injector.get(AuthenticationService);
    this.credentialsService = this.injector.get(CredentialsService);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = this.credentialsService.credentials;
    const headers: { [k: string]: string } = {};
    headers[config.Authorization] = `Bearer ${user ? user.signedKey : ''}`;
    headers[config.isSigned] = 'true';
    headers[config.HeaderTenantKey] = this.tenantName;
    headers[config.HeaderUserIDKey] = user ? user.kullaniciID.toString() : '';
    headers[config.HeaderDilKoduKey] = user ? user.dilKodu : '';
    headers[config.HeaderDilKoduID] = user ? user.dilID.toString() : '';
    headers[config.HeaderAdiSoyadi] = user
      ? SenyonetUtils.turkishtoEnglish(user.adiSoyadi?.toString())
      : '';
    headers[config.HeaderTesisId] = this.facilityService?.tesisID;
    headers[config.HeaderBolumId] = this.facilityService?.bolumID;
    headers[config.HeaderBlokId] = this.facilityService?.blokID;
    headers[config.HeaderFirmaId] = this.facilityService?.firmaID;
    headers[config.HeaderFirmaKisiId] = this.facilityService?.firmaKisiID;
    headers[config.HeaderHesapID] = user ? user.cariHesapID?.toString() : '';
    headers[config.HeaderRefererUrl] = window.location.host;
    headers[config.HeaderAppID] = environment.appID;
    request = request.clone({
      setHeaders: headers,
    });

    return next.handle(request);
  }
}
