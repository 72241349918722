import { Component, Input, OnInit } from '@angular/core';
import { CredentialsService } from '../credentials.service';

import { I18nService } from './i18n.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() icon = false;

  constructor(private i18nService: I18nService, private credentialService: CredentialsService) { }

  ngOnInit(): void { }

  setLanguage(language: string): void {
    this.i18nService.language = language;
    const user = this.credentialService.credentials;
    user.dilKodu = language;
    const digits = language.substring(0, 2);
    switch (digits) {
      case 'tr': user.dilID = 1; break;
      case 'en': user.dilID = 2; break;
      case 'ru': user.dilID = 3; break;
      case 'uz': user.dilID = 4; break;
      case 'ar': user.dilID = 5; break;
      default: user.dilID = 1;
    }
    this.credentialService.setCredentials(user);
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }
}
