import { NotFoundDataComponent } from './not-found-data/not-found-data.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared';
import { I18nModule } from 'src/app/core/i18n';
import { HttpClientModule } from '@angular/common/http';
import { RegisterComponent } from './register/register.component';
import { PasswordComponent } from './password/password.component';
import { PasswordRefreshComponent } from './password-refresh/password-refresh.component';
import { CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    NotFoundDataComponent,
    PasswordComponent,
    PasswordRefreshComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    I18nModule,
    HttpClientModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    CountdownModule,
  ],
  providers: [
    {
      provide: CountdownGlobalConfig,
      useFactory: () => {
        return { format: `m:ss` };
      },
    },
  ],
})
export class LoginModule {}
