<div class="d-flex flex-stack">
    <h1 mat-dialog-title class="mb-0">{{ imageName }}</h1>
    <button class="btn btn-sm btn-icon btn-active-light-danger" matTooltip="{{ 'Global.Close' | translate }}" (click)="closeDialog()">
    <span
      class="svg-icon svg-icon-1"
      [inlineSVG]="'assets/icons/Navigation/Close.svg'"
      [cacheSVG]="true"
    ></span>
  </button>
</div>
<div mat-dialog-content>
    <div class="bg-image" [style.background-image]="'url(' + image + ')'"></div>
</div>