<div class="d-flex flex-column flex-lg-row flex-column-fluid">
  <div
    class="d-flex flex-column flex-lg-row-auto w-lg-400px w-xl-600px positon-xl-relative bg-primary"
  >
    <div *ngIf="!isAkMerkez"
      class="d-flex flex-column position-xl-fixed top-0 bottom-0 w-lg-400px w-xl-600px"
    >
      <div class="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
        <a href="#" class="py-9 mb-5">
          <img alt="Logo" [src]="APP_LOGO" class="h-60px" />
        </a>
        <h1 class="text-primary-inverse fw-bolder fs-2qx pb-5 pb-md-10">
          {{ APP_NAME }}
        </h1>
      </div>
      <div class="d-flex flex-row-auto min-h-100px owl-wrapper">
        <owl-carousel-o [options]="customOptions" #owlCat *ngIf="loadSlider">
          <ng-container *ngFor="let image of imagesData">
            <ng-template carouselSlide>
              <div class="d-flex flex-row-fluid flex-column text-center">
                <p class="text-primary-inverse fw-bold fs-2 p-10 pt-lg-20">
                  {{ image.title | translate }}
                </p>
                <img
                  class="d-none d-lg-block"
                  [src]="image.src"
                  [alt]="image.alt"
                  title="{{ image.title | translate }}"
                />
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
    <img style="height: 100vh;" *ngIf="isAkMerkez" [src]="imagesData[0].src" [alt]="imagesData[0].alt" title="Ak Merkez">
  </div>
  <div class="d-flex flex-column flex-lg-row-fluid py-10">
    <div class="d-flex flex-center flex-column flex-column-fluid">
      <div class="w-lg-500px p-10 p-lg-15 mx-auto">
        <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
          <div class="text-start mb-10">
            <h1 class="text-dark fw-bolder mb-3">
              {{ "Login.Welcome" | translate }}
            </h1>
            <div class="text-gray-500 fw-semibold fs-6">
              {{ "Login.Description" | translate }}
            </div>
          </div>

          <div
            class="alert alert-danger mb-3"
            role="alert"
            [hidden]="!error || isLoading"
          >
            {{ "Login.FormError" | translate }}
          </div>
          <div fxLayout="column">
            <mat-form-field
              floatLabel="always"
              [hideRequiredMarker]="true"
              *ngIf="!isISS; else issUserName"
            >
              <input
                type="text"
                matInput
                class="form-control form-control-solid"
                formControlName="username"
                autocomplete="username"
                [placeholder]="(isAkMerkez ? 'Login.UserNameForAkMerkez' : 'Login.UserName') | translate"
                required
              />
              <mat-error
                *ngIf="
                  loginForm.controls.username.invalid &&
                  loginForm.controls.username.touched
                "
              >
                <span> {{ (isAkMerkez ? "Login.UserNameRequiredForAkMerkez" : "Login.UserNameRequired") | translate }} </span>
              </mat-error>
            </mat-form-field>
            <ng-template #issUserName>
              <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
                <input
                  type="text"
                  class="form-control form-control-solid"
                  matInput
                  formControlName="username"
                  autocomplete="username"
                  [placeholder]="'Login.UserNameforISS' | translate"
                  required
                />
                <mat-error
                  *ngIf="
                    loginForm.controls.username.invalid &&
                    loginForm.controls.username.touched
                  "
                >
                  <span>
                    {{ "Login.UserNameforISSRequired" | translate }}
                  </span>
                </mat-error>
              </mat-form-field>
            </ng-template>
            <mat-form-field
              floatLabel="always"
              [hideRequiredMarker]="true"
              class="mb-4"
            >
              <input
                type="password"
                matInput
                class="form-control form-control-solid"
                formControlName="password"
                autocomplete="current-password"
                [placeholder]="'Login.Password' | translate"
                required
              />
              <mat-error
                *ngIf="
                  loginForm.controls.password.invalid &&
                  loginForm.controls.password.touched
                "
              >
                <span> {{ "Login.PasswordRequired" | translate }} </span>
              </mat-error>
            </mat-form-field>
            <div fxLayoutAlign="space-between stretch" class="mb-5">
              <mat-slide-toggle
                color="primary"
                formControlName="remember"
                class="mb-4"
              >
                {{ "Login.RememberMe" | translate }}
              </mat-slide-toggle>

              <a class="btn-link fs-6 fw-bolder" routerLink="/password">
                {{ "Login.SifremiUnuttum" | translate }}
              </a>
            </div>

            <button
              class="btn btn-lg btn-primary w-100 mb-5"
              type="submit"
              [disabled]="loginForm.invalid || isLoading"
              [attr.data-indicator]="isLoading ? 'on' : null"
            >
              <span class="indicator-label">
                {{ "Login.Title" | translate }}
              </span>
              <span class="indicator-progress"
                >{{ "Global.LutfenBekleyin" | translate }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>

          <ng-container *ngIf="!isTeknik">
            <div class="text-center text-muted fw-bolder my-10">
              {{ "Login.Yada" | translate }}
            </div>

            <div class="text-center mb-10">
              <div class="text-gray-400 fw-bold fs-4">
                {{ "Login.DoNotHaveAnAccount" | translate }}
                <a class="btn-link fw-bolder" routerLink="/register">{{
                  "Login.Register" | translate
                }}</a>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
    </div>

    <div class="d-flex flex-center flex-wrap px-5">
      <div class="d-flex fw-semibold text-primary fs-base">
        <app-language-selector></app-language-selector>
      </div>
    </div>
  </div>
</div>
